<div class="general-container register flex flex-col" [ngClass]="{'mob-general': isMobileApp === 'mob'}">
    <div *ngIf="isMobileApp === undefined && list.length > 2 && updateSpecificDocument !== true" class="progress_bar_container">
        <div class="progress_bar" [ngStyle]="{ width: percent + '%' }"></div>
    </div>
    <div class="body-content">
        <ng-container>
            <!--E-mail step-->
            <div *ngIf="step === emailIndex && !loading" class="step pwd">
                <div class="span-container">
                    <span id="page-title">Registro: persona física</span>
                </div>
                <div *ngIf="emailPage === 'email'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">E-mail</h2>
                        <p class="font-sans text-center text-xl text-gray-40" *ngIf="!editM">{{ 'register.email.email_need' | translate }}</p>
                        <p class="font-sans text-center text-xl text-gray-40" *ngIf="editM">{{ 'register.email.edit' | translate }}</p>
                    </div>
                    <fun-register-person-email
                    #registerPersonEmail
                    *ngIf="!editM"
                    [data]="info"
                    profileType="1"
                    (sendErrorEvent)="getError()"
                    (sendInfoEvent)="getEmailData($event)"
                    (submitEvent)="sendXHR === 'primaryDark' ? next() : null"
                    (acceptConditionsEvent)="getConditions($event)"
                    (acceptOffersEvent)="getOffers($event)"
                    (checkRefCodeEvent)="checkRefCode($event)"
                    (selectTermsAndConditionsEvent)="showTermsAndConditions($event)">
                    </fun-register-person-email>
                    <fun-register-person-edit-email
                        (sendErrorEvent)="getError()"
                        (sendInfoEvent)="getNewEmailData($event)"
                        *ngIf="editM"
                    ></fun-register-person-edit-email>
                </div>
                <div *ngIf="emailPage === 'terms'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">Términos y condiciones</h2>
                        <div class="check-conditions">
                            <fun-spinner *ngIf="!hasPrivacyText"></fun-spinner>
                            <div class="conditions-container" *ngIf="hasPrivacyText" [innerHTML]="termsTxt"></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="emailPage === 'privacity'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">Política de privacidad</h2>
                        <div class="check-conditions">
                            <fun-spinner *ngIf="!hasTermsTxt"></fun-spinner>
                            <div class="conditions-container" *ngIf="hasTermsTxt" [innerHTML]="privacyTxt"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Name steo-->
            <div *ngIf="step === generateProfileIndex && !loading" class="step name" [ngClass]="{'download-app': wantToDownload}">
                <div *ngIf="wantToDownload" class="span-container">
                    <span id="page-title">Descarga nuestra app</span>
                </div>
                <div *ngIf="!wantToDownload" class="span-container">
                    <span id="page-title">Información básica</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <img *ngIf="wantToDownload" src="../../../../../assets/images/illustrations/notifications.svg">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="wantToDownload">{{ 'register.know.app' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40" *ngIf="wantToDownload">{{ 'register.know.mail_verify' | translate }}</p>
                    <img class="appstore_img" *ngIf="device === 'iOS' && wantToDownload && currentLang === 'es' " src="../../../../../assets/icons/apple-store.svg" (click)="openAppStore('https://apps.apple.com/es/app/fundeen/id1510581729')">
                    <img class="appstore_img" *ngIf="device === 'iOS' && wantToDownload && currentLang === 'en' " src="../../../../../assets/icons/app-store-en.svg" (click)="openAppStore('https://apps.apple.com/es/app/fundeen/id1510581729')">
                    <img class="appstore_img"  *ngIf="device !== 'iOS' && wantToDownload && currentLang === 'es' " src="../../../../../assets/icons/app-store.svg" (click)="openAppStore('https://play.google.com/store/apps/details?id=com.fundeen.app')">
                    <img class="appstore_img"  *ngIf="device !== 'iOS' && wantToDownload && currentLang === 'en' " src="../../../../../assets/icons/app-store-en.svg" (click)="openAppStore('https://play.google.com/store/apps/details?id=com.fundeen.app')">
                    <!---->
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]" *ngIf="!wantToDownload">{{ 'register.know.active_portfolio' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40" *ngIf="!wantToDownload">{{ 'register.know.lets_start' | translate }}</p>
                </div>
                <fun-register-person-name
                    *ngIf="!wantToDownload"
                    [data]="info"
                    (sendInfoEvent)="getNameInfo($event)"
                    (sendErrorEvent)="getError()"
                    (submitEvent)="sendXHR === 'primaryDark' ? next() : null"
                ></fun-register-person-name>
            </div>
            <!--Phone step-->
            <div *ngIf="step === phoneIndex && !loading" class="step phone">
                <div class="span-container">
                    <span id="page-title">Número de teléfono</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.phone.phone' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.phone.will_use' | translate }}</p>
                </div>
                <fun-register-person-phone
                    [data]="info"
                    (sendInfoEvent)="getPhoneData($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-person-phone>
            </div>
            <!--Code step-->
            <div *ngIf="step === codeIndex && !loading" class="step code">
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.code.introduce' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.code.send' | translate }}<span>+{{ info.prefix }} {{ info.phone }}</span>
                        {{ 'register.code.ok' | translate }} <span class="span_b" (click)="prev()">{{ 'register.code.change' | translate }}</span>
                    </p>
                </div>
                <!-- <fun-register-person-code
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getCodeInfo($event)"
                    (resendCodeEvent)="resendCode('phone')"
                ></fun-register-person-code> -->
            </div>
            <!--Code emailCode step-->
            <div *ngIf="step === emailCodeindex && !loading" class="step code">
                <div class="span-container">
                    <span id="page-title">Validación de email</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.code.mail' | translate }}</h2>
                    <h2 class="email_ad">{{ info.email }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.code.check_mail' | translate }} <span (click)="editMail()" class="span_b">{{ 'register.code.edit_mail' | translate }}</span> </p>
                </div>
                <!-- <fun-register-person-code
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getCodeInfo($event)"
                    (resendCodeEvent)="resendCode('mail')"
                ></fun-register-person-code> -->
            </div>
            <!---->
            <div *ngIf="step === verifyEmailIndex && !loading" class="step ">
                <fun-overal-message [text]="textAcceptMail" [image]="imageAcceptMail" [btnList]="btnListAcceptMail" (btnEvent)="removeModal()"></fun-overal-message>
            </div>
            <div *ngIf="step === nationalityIndex && !loading" class="step date">
                <div class="span-container">
                    <span id="page-title">Nacionalidad</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.nationality.nationality' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.nationality.txt' | translate }}</p>
                </div>
                <fun-register-person-natinality
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getNationalityInfo($event)"
                ></fun-register-person-natinality>
            </div>
            <!-- id step-->
            <div  *ngIf="step === messageIndex && !loading" class="step">
                <fun-overal-message [text]="text" [image]="image" [btnList]="btnListN"></fun-overal-message>
            </div>
             <!-- idOk step-->
             <div  *ngIf="step === documentOkFileIndex && !loading" class="step">
                <fun-overal-message [text]="textIdOk" [image]="image" [btnList]="btnList" (btnEvent)="finishProccess()"></fun-overal-message>
            </div>
            <!--Address step-->
            <div *ngIf="step === addressIndex && !loading" class="step">
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.identity.address' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.identity.address_txt' | translate }}</p>
                </div>
                <fun-register-person-address
                    [data]="info"
                    [profile]="profile"
                    [towns]="towns"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getAddressInfo($event)"
                ></fun-register-person-address>
            </div>
            <!--id document step-->
            <div *ngIf="step === idDocumentIndex && !loading" class="step date">
                <div class="span-container">
                    <span id="page-title">Número de documento de identidad</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.identity.id' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.identity.id_introduce' | translate }}</p>
                </div>
                <fun-register-person-document-id
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    [addSpecificNie]="addSpecificNie"
                    (sendInfoEvent)="getDniInfo($event)"
                    (sendErrorEvent)="getError()">
                </fun-register-person-document-id>
            </div>
            <!---->
            <fun-register-file
                *ngIf="step === fileIndex && !loading"
                #registerPersonFile
                [info]="info"
                [isRegister]="isRegister"
                [canGoBack]="documentCanBack"
                [stepList]="specificFileSteps"
                [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                (documentEvent)="getDocuments($event)"
                (lastStepEvent)="lastStepPerson()"
                (finishRegisterEvent)="finishRegister()"
                (prevStepEvent)="prev()"
                (updateDocumentEvent)="emitDocs($event)"
                (updateNumberEvent)="updateDocNumber($event)"
                #registerDocument
            ></fun-register-file>
            <!---->
            <fun-register-success *ngIf="step === successStepIndex && !loading"
            [userInfo]="userInfo" [profile]="profile" [acceptedTerms]="true" [mpSuccess]="hasToacceptMpTerms" (finishEvent)="finishProccess()"></fun-register-success>
            <!---->
            <fun-register-person-last-step
                #registerPersonAccredited
                *ngIf="step === accreditIndex && !loading"
                [lastStep]="lastStep"
                [userInfo]="userInfo"
                [profile]="profile"
                [onlyAccredit]="onlyAccredit"
                (accreditationChecksEvent)="accreditationChecks($event)"
                (bankDocumentEvent)="getBankDocument($event)"
                (acceptLwTermsEvent)="acceptLw($event)"
                (finishEvent)="finishProccess()"
                (signaturitAccreditEvent)="getSignaturitUrl()"
                (otherMomentEventEvent)="otherMomentAccredit()"
                (signaturitSignedEvent)="signaturitSigned()"
            ></fun-register-person-last-step>
            <!--civilStatus-->
            <div *ngIf="step === civilStatusindex && !loading" class="step">
                <div class="header">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.civil_status.title' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.civil_status.info' | translate }}</p>
                </div>
                <fun-register-person-civil-status
                    *ngIf="step === civilStatusindex && !loading"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getCivilStatus($event)"
                ></fun-register-person-civil-status>
            </div>
            <!---->
            <div *ngIf="step === mangopayWalletIndex && !loading" class="step full-grid">
                <div class="second-grid">
                    <app-register-mangopay-terms (checkActionEvent)="checkMangopayTerms($event)"></app-register-mangopay-terms>
                </div>
            </div>
            <!---->
            <fun-overal-message *ngIf="step === investMessageIndex && !loading"
            [text]="textM" [image]="imageM" [btnList]="btnListM" (btnEvent)="next()"></fun-overal-message>
            <!---->
            <fun-overal-message *ngIf="step === documentNieMsgIndex && !loading"
            [text]="textNie" [image]="imageNie" [btnList]="btnListM" (btnEvent)="next()"></fun-overal-message>
            <!---->
            <fun-overal-message *ngIf="step === okInvestMessageIndex && !loading"
            [text]="textI" [image]="imageM" [btnList]="btnListM" (btnEvent)="continueInvest()"></fun-overal-message>
            <!---->
            <fun-overal-message *ngIf="step === investFinishMessageIndex && !loading"
            [btnList]="btnWrongDoc" [text]="textF" [image]="imageM" (btnEvent)="goToMyProfile(); finishProccess()"></fun-overal-message>
            <!--loading-->
            <fun-overal-message *ngIf="step === mailValidatedIndex && !loading" [idG]="'email_validation'"
            [text]="textMailV" [image]="imageMailV"></fun-overal-message>
            <!--loading-->
            <fun-overal-message *ngIf="step === phoneValidatedIndex && !loading" [idG]="'sms_validation'"
            [text]="textPhoneV" [image]="imagePhoneV"></fun-overal-message>
            <!--loading-->
            <div *ngIf="loading">
                <fun-spinner></fun-spinner>
            </div>
        </ng-container>
    </div>

    <div *ngIf="step !== fileIndex && step !== accreditIndex && step !== investFinishMessageIndex && step !== investMessageIndex && step !== okInvestMessageIndex
    && step !== documentOkFileIndex && !loading && step !== successStepIndex && step !== verifyEmailIndex" class="mobile-footer">
        <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="(!wantToDownload && step === generateProfileIndex) || (step !== generateProfileIndex)" (eventClick)="next()" class="next-btn"
        [buttonType]="sendXHR" title="{{ 'register.btn.continue' | translate }}"  icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="changeWay()" *ngIf="step === 0 && isRegister && !editM">{{ 'register.btn.company' | translate }}.</h4>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        *ngIf="step >= nationalityIndex || step === phoneIndex" (click)="finishRegister()" id="not_now">{{ 'register.btn.another_time' | translate }}</h4>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        *ngIf="step === generateProfileIndex && wantToDownload" (click)="continueRegister();">{{ 'register.btn.continue_here' | translate }}</h4>
        <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="step === emailIndex && emailPage !== 'email'" (eventClick)="showTermsAndConditions('email')" class="back-btn"
            [buttonType]="'ghost'" title="{{ 'register.btn.back' | translate }}" id="go_back"></fun-new-button>
    </div>

    <div *ngIf="step !== fileIndex && step !== accreditIndex && step !== investFinishMessageIndex && step !== investMessageIndex && step !== okInvestMessageIndex
    && step !== documentOkFileIndex && !loading && step !== successStepIndex && step !== verifyEmailIndex" class="desktop-footer">
        <div class="content-footer">
            <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="step === emailIndex && emailPage !== 'email'" (eventClick)="showTermsAndConditions('email')" class="back-btn"
            [buttonType]="'ghost'" title="{{ 'register.btn.back' | translate }}" id="go_back"></fun-new-button>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            (click)="changeWay()" *ngIf="step === 0 && isRegister && !editM" id="legal_person">{{ 'register.btn.company' | translate }}</h4>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            *ngIf="step >= nationalityIndex || step === phoneIndex" (click)="finishRegister()" id="not_now">{{ 'register.btn.another_time' | translate }}</h4>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            *ngIf="step === generateProfileIndex && wantToDownload" (click)="continueRegister();" id="stay_on_mobile">{{ 'register.btn.continue_here' | translate }}</h4>
            <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="(!wantToDownload && step === generateProfileIndex) || (step !== generateProfileIndex)" (eventClick)="next()" class="next-btn"
            [buttonType]="sendXHR" title="{{ 'register.btn.continue' | translate }}"  icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        </div>
    </div>
</div>