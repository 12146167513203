<div class="general-container">
  <!--<app-graphics-nav [page]="'daily-production'" initial="0"></app-graphics-nav>-->

  <ion-slides class="nav-slider" pager="false" [options]="navSlideOpts" id="resalLinesC" #resalLinesC>
    <ion-slide [style.background-color]="prodRealColor" (click)="resaltLine('prod-real')">
      <div>
        <p>{{ 'DASHBOARD.DAILY_PRODUCTION.REAL_PROD' | translate }}</p>
        <h2 title="{{ totalReal | number: '1.0-0':'es' }} kWh">{{ totalReal | number: '1.0-0':'es' }} kWh</h2>
      </div>
    </ion-slide>
    <ion-slide [style.background-color]="prodEstimColor" (click)="resaltLine('prod-estim')">
      <div>
        <p>{{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED_PROD' | translate }}</p>
        <h2 title="{{ totalEstim | number: '1.0-0':'es' }} kWh">{{ totalEstim | number: '1.0-0':'es' }} kWh</h2>
      </div>
    </ion-slide>
    <ion-slide [style.background-color]="recEstimColor" (click)="resaltLine('rec-estim')">
      <div>
        <p>{{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED_RESOURCE' | translate }}</p>
        <h2 title="{{ totalRec | number: '1.0-0':'es' }} kWh/m²">{{ totalRec | number: '1.0-0':'es' }} kWh/m²</h2>
      </div>
    </ion-slide>
  </ion-slides>

  <nav id="navResaltLinesC" #navResaltLinesC>
    <div [style.background-color]="prodRealColor" (click)="resaltLine('prod-real')">
      <p>{{ 'DASHBOARD.DAILY_PRODUCTION.REAL_PROD' | translate }}</p>
      <h2 title="{{ totalReal | number: '1.0-0':'es' }} kWh">{{ totalReal | number: '1.0-0':'es' }} kWh</h2>
    </div>
    <div [style.background-color]="prodEstimColor" (click)="resaltLine('prod-estim')">
      <p>{{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED_PROD' | translate }}</p>
      <h2 title="{{ totalEstim | number: '1.0-0':'es' }} kWh">{{ totalEstim | number: '1.0-0':'es' }} kWh</h2>
    </div>
    <div [style.background-color]="recEstimColor" (click)="resaltLine('rec-estim')">
      <p>{{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED_RESOURCE' | translate }}</p>
      <h2 title="{{ totalRec | number: '1.0-0':'es' }} kWh/m²">{{ totalRec | number: '1.0-0':'es' }} kWh/m²</h2>
    </div>
  </nav>

  <div class="full-graphic">
    <div class="label">
      <div
        *ngIf="estimProdData[indexClicked] || realProdData[indexClicked] || estimRecData[indexClicked]"
        class="legendContainer"
      >
        <p
          title="{{ realProdData[indexClicked] | number: '1.0-2':'es' }} kWh"
          *ngIf="realProdData[indexClicked]"
          class="realPrData"
          [ngClass]="{ realPrNoSelected: prodRealClicked === false }"
        >
          {{ realProdData[indexClicked] | number: '1.0-0':'es' }} kWh
        </p>
        <p
          title="{{ estimProdData[indexClicked] | number: '1.0-2':'es' }} kWh"
          *ngIf="estimProdData[indexClicked]"
          class="estimPrData"
          [ngClass]="{ realEstimProdNoSelected: prodEstimClicked === false }"
        >
          {{ estimProdData[indexClicked] | number: '1.0-0':'es' }} kWh
        </p>
        <p
          title="{{ estimRecData[indexClicked] | number: '1.0-2':'es' }} kWh/m²"
          *ngIf="estimRecData[indexClicked]"
          class="estimRcData"
          [ngClass]="{ realEstimRecNoSelected: recEstimClicked === false }"
        >
          {{ estimRecData[indexClicked] | number: '1.0-0':'es' }} kWh/m²
        </p>
      </div>
    </div>
    <div class="no-margin">
      <div class="center-line"></div>
      <ion-spinner *ngIf="!loaded" name="lines"></ion-spinner>
      <div #chartContainer class="canvas-container">
        <div class="scroll-container">
          <div class="graph-container">
            <canvas #lineChart id="myChart">{{ chart }}</canvas>
          </div>
        </div>
      </div>
    </div>

    <ion-slides class="weather-slider" pager="false" [options]="slideOpts" (ionSlideTap)="changePoint()" #sliderWeather>
      <ion-slide *ngFor="let project of projectInfo">
        <div [ngClass]="{ clicked: project.index === indexClicked }">
          <p>{{ project.time }} : 00</p>
          <img src="../../../../assets/icons/cloud.svg" alt="weather" *ngIf="project.weather === 'cloudy'" />
          <img src="../../../../assets/icons/cloud-sun.svg" alt="weather" *ngIf="project.weather === 'partly-cloudy'" />
          <img src="../../../../assets/icons/cloud-lightning.svg" alt="weather" *ngIf="project.weather === 'overcast'" />
          <img src="../../../../assets/icons/clear.svg" alt="weather" *ngIf="project.weather === 'clear'" />
          <p>{{ project.temperature | number: '1.0-0':'es' }}º</p>
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</div>
