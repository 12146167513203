<div *ngIf="step === 0">
  <div class="general-container">
    <img src="../../assets/images/img/info.svg" alt="info" class="info" (click)="openInfo()">
    <div class="row operation" *ngIf="bankNumber !== null">
        <div class="col-6 col-sm-12 first">
            <h2 class="font-sans text-eggplant-100 text-xl">{{ 'MOVEMENTS.MONEY-OUT.BANK-TRANSFER' | translate }} </h2>
            <form [formGroup]="cashForm" (submit)="outMoney()" >
                <div class="row">
                    <div class="col-12">
                        <h4 class="font-sans text-eggplant-80 text-l">{{ 'MOVEMENTS.MONEY-OUT.TRANSFER-TO' | translate }}</h4>
                        <div class="bank-count">
                            <div class="bank">
                                <h3 class="font-sans text-eggplant-80 text-l" *ngIf="loading !== true">{{ bankNumber }} <span class="change text-m" (click)="change()">{{ 'MOVEMENTS.MONEY-OUT.CHANGE' | translate }}</span></h3>
                                <ion-skeleton-text *ngIf="loading" style="width: 90%" animated></ion-skeleton-text>
                            </div>
                        </div>
                        <label *ngIf="loading !== true" class="available font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.MONEY-OUT.AVAILABLE' | translate }} {{ available | localeNumber }} €</label>
                        <ion-skeleton-text class="available" *ngIf="loading" style="width: 30%" animated></ion-skeleton-text>
                        <fun-input
                        *ngIf="loading !== true"
                        [disabled]="disableInputs"
                        placeholder="00.00 €"
                        [parentForm]="cashForm"
                        [formControlKeys]="formControlKeys"
                        [errorMessages]="errorMessages"
                        [isSubmit]="isSubmit"
                        controlName="money"
                        type="number"
                        [errorLogin]="errorAmount"
                        labelName="{{ 'MOVEMENTS.MONEY-OUT.QUANTITY-WITHDRAW' | translate }}"
                        ></fun-input>
                        <fun-input
                        *ngIf="loading !== true"
                        [disabled]="disableInputs"
                        placeholder="ej: {{ 'MOVEMENTS.MONEY-OUT.DIVIDENDS' | translate }}"
                        [parentForm]="cashForm"
                        [formControlKeys]="formControlKeys"
                        [errorMessages]="errorMessages"
                        [isSubmit]="isSubmit"
                        controlName="concept"
                        type="text"
                        [errorLogin]="errorConcept"
                        labelName="{{ 'MOVEMENTS.MONEY-OUT.CONCEPT' | translate }}"
                         ></fun-input>
                         <div *ngIf="loading" class="inp-container">
                             <div class="inp">
                                <ion-skeleton-text style="width: 90%; height: 12px;" animated></ion-skeleton-text>
                             </div>
                             <div class="inp">
                                <ion-skeleton-text style="width: 90%; height: 12px;" animated></ion-skeleton-text>
                             </div>
                         </div>
                    </div>
                    <div class="col-12 button-container flex md:block items-center justify-center">
                        <fun-new-button [size]="'xl'" [buttonType]="sendXHR" title="{{ 'MOVEMENTS.MONEY-OUT.TRANSFER' | translate }}" type="submit" class="button"
                        (eventClick)="outMoney()"></fun-new-button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-6 col-sm-12 desktop-adv">
            <p class="font-sans text-l text-eggplant-100/[0.55]">{{ 'MOVEMENTS.MONEY-OUT.TEXT-1' | translate }}</p>
            <p class="font-sans text-l text-eggplant-100/[0.55]">{{ 'MOVEMENTS.MONEY-OUT.TEXT-2' | translate }}</p>
        </div>
    </div>
    <div class="row sucess" *ngIf="bankNumber === null">
        <img src="../../assets/images/img/ico-success.svg" alt="congratulations">
        <div class="col-12">
            <h2 class="font-sans text-eggplant-100 text-xl">{{ 'MOVEMENTS.MONEY-OUT.SUCCESSFUL' | translate }}</h2>
            <p>{{ 'MOVEMENTS.MONEY-OUT.TEXT-3' | translate }}</p>
        </div>

        <div class="col-6 col-sm-12 btn-container">
            <fun-new-button [buttonType]="'primaryDark'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.PROJECTS' | translate }}"
            (eventClick)="goProjects()" class="button" [classExtra]="' !w-full '"></fun-new-button>
        </div>
        <div class="col-6 col-sm-12 btn-container">
            <fun-new-button [buttonType]="'secondary'" [size]="'xl'" title="{{ 'MOVEMENTS.MONEY-OUT.MOVEMENTS' | translate }}"
            (eventClick)="closeModal()" class="secondary" [classExtra]="' !w-full '"></fun-new-button>
        </div>
    </div>
  </div>
</div>
<div *ngIf="step === 1">
    <app-money-out-change-account [walletId]="walletId" [username]="username" (goProject)="goProjects()"></app-money-out-change-account>
</div>
<div *ngIf="step === 2">
    <fun-overal-message [text]="text" [image]="2" ></fun-overal-message>
</div>