import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-inverstor-profile',
  templateUrl: './inverstor-profile.component.html',
  styleUrls: ['./inverstor-profile.component.scss'],
})
export class InverstorProfileComponent implements OnInit {

  @Output() public closeModalEvent = new EventEmitter<any>();
  @Output() public otherMomentEvent = new EventEmitter<any>();
  @Output() public nextEvent = new EventEmitter<any>();
  @Output() public prevEvent = new EventEmitter<any>();
  @Input() profile: any;

  step = 0;
  rangeValueAnnualInvestment = 5;
  rangeAnnualGrossIncome = 20;
  rangeFinantialPatrimony = 20;

  rangeOwnResources = 30;
  rangeTurnover = 30;
  rangebalance = 30;

  hasExperienceSelected = false;
  worksInSectorSelected = false;
  declareIsCorrect = false;

  experienceModel: any;

  btnType = 'primaryDark';

  loading = false;

  constructor(public chdRef: ChangeDetectorRef, private profileService: ProfileService) { }

  ngOnInit() {
    if (this.profile.profileType === 1) {
      this.experienceModel = {
        finantialPatrimony:  this.rangeFinantialPatrimony * 1000,
        annualGrossIncome:  this.rangeAnnualGrossIncome * 1000,
        hasExperienceInFinantialSector: false,
        annualInvestmentActivities: this.rangeValueAnnualInvestment,
        dontRequestInvestmentExperienceInfo: false
      };
    } else {
      this.experienceModel = {
        ownResources: this.rangeOwnResources * 1000,
        turnover: this.rangeTurnover * 1000,
        balance: this.rangebalance * 1000,
        annualInvestmentActivities: this.rangeValueAnnualInvestment,
        hasExperienceInFinantialSector: false,
        dontRequestInvestmentExperienceInfo: false
      };
    }
    this.chdRef.detectChanges();
  }

  changeDataRange(value, type) {
    switch (type) {
      case 'anual-inv':
        this.rangeValueAnnualInvestment = value.detail.value;
        this.experienceModel.annualInvestmentActivities = value.detail.value;
        break;
      case 'annual-gross':
        this.rangeAnnualGrossIncome = value.detail.value;
        this.experienceModel.annualGrossIncome = value.detail.value * 1000;
        break;
      case 'finantial-patrimony':
        this.rangeFinantialPatrimony = value.detail.value;
        this.experienceModel.finantialPatrimony = value.detail.value * 1000;
        break;
      case 'ownResources':
        this.rangeOwnResources = value.detail.value;
        this.experienceModel.ownResources = value.detail.value * 1000;
        break;
      case 'turnover':
        this.rangeTurnover = value.detail.value;
        this.experienceModel.turnover = value.detail.value * 1000;
        break;
      case 'balance':
        this.rangebalance = value.detail.value;
        this.experienceModel.balance = value.detail.value * 1000;
        break;
    }
    this.chdRef.detectChanges();
  }

  checkOption(value) {
    switch (value) {
      case 'experience':
        this.hasExperienceSelected = !this.hasExperienceSelected;
        break;
      case 'works':
        this.worksInSectorSelected = !this.worksInSectorSelected;
        break;
      case 'dechare':
        this.declareIsCorrect = !this.declareIsCorrect;
        this.controlBtn();
        break;
    }
    this.experienceModel.hasExperienceInFinantialSector = (this.hasExperienceSelected || this.worksInSectorSelected) ? true : false;
    this.chdRef.detectChanges();
  }

  next() {
    if (this.step === 0) {
      this.step = 1;
    } else {
      this.experienceModel.annualInvestmentActivities = this.hasExperienceSelected ? this.experienceModel.annualInvestmentActivities : 0;
      this.loading = true;
      this.profileService.updateProfile(this.experienceModel).subscribe(prof => {
        this.closeModalEvent.emit();
        this.nextEvent.emit(this.profile);
        this.chdRef.detectChanges();
      });
    }
    this.controlBtn();
    this.chdRef.detectChanges();
  }
  prev() {
    if (this.step === 1) {
      this.step = 0;
    } else {
      this.closeModalEvent.emit();
      this.prevEvent.emit();
    }
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  controlBtn() {
    this.btnType = (this.step === 1 && !this.declareIsCorrect) ? 'primaryDark-disabled' : 'primaryDark';
    this.chdRef.detectChanges();
  }

  finish() {
    this.otherMomentEvent.emit();
    this.closeModalEvent.emit();
    this.chdRef.detectChanges();
  }

}
