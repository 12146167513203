
<div #container id="container-generic">
  <div *ngIf="!isLoaded" no-bounce>
    <div class="general-container skl">
      <fun-spinner [color]="'medium'"></fun-spinner>
    </div>
  </div>

  <div *ngIf="isLoaded" id="container"
  [ngClass]="{ 'bg-detail-financing' : (project.status == 2 || project.status == 3 || project.status == 4 || project.status == 12 || project.status == 14 || project.status == 15) && !isFlexibleCompensation,
  'bg-mob-generic': !isFlexibleCompensation, 'bg-mob-ric': isFlexibleCompensation }"
    class="rnd border-eggplant-100/[0.2] min-h-screen pb-48">
    <div class="w-full pt-32 md:pr-[46px] md:pl-32 relative rnd-2"
    [ngClass]="{ 'bg-project-header' : project.status > 4 && project.status < 12 && project.status !== 14 && project.status !== 15 }">
      <div class="flex pl-12 md:pl-0">
        <div class="fixed right-12 md:right-[46px] md:pt-[11px] z-50">
          <img class="cursor-pointer" (click)="closeModal()" src="../../../../../assets/images/img/ico-close.svg" alt="ico-close">
        </div>
      </div>
      <div *ngIf="isLoaded" class="md:grid grid-cols-4 md:grid-cols-14 md:gap-x-32 lg:gap-x-32 place-content-center relative pt-[52px] mb-80">
        <div class="md:col-span-10 md:col-start-3">
          <h1 class="text-center text-h600 md:text-h1000 font-sans px-16 md:px-0 text-eggplant-100">{{project.name}}</h1>
        </div>
      </div>
      <div *ngIf="project.status > 4 && project.status < 12"
        class="md:grid grid-cols-4 md:grid-cols-14 md:gap-x-32 lg:gap-x-32 place-content-center relative slider-p pt-32 md:pt-0 md:mb-32 pl-8 md:pl-0 scrollbar-none mob-none">
        <div class="md:col-span-10 md:col-start-3 relative mb-[-1px]">
          <div *ngIf="detailProject.type === 1 && detailProject.id !== 38" class="cursor-pointer absolute left-[-34px] top-[14px]" (click)="prevSliderHeader()">
            <fun-icon  [name]="'arrow-left'" [style]="'w-24 h-24 stroke-[4px] stroke-eggplant-100/[0.5]'"></fun-icon>
          </div>
          <ion-slides class="nav-slider" pager="false" [options]="navSlideOpts" id="headerSlider" #headerSlider>
            <ion-slide class="w-auto mr-8">
              <div (click)="viewNavclick('overview')" class="appear px-16 py-8 eyelash cursor-pointer"
              [ngClass]="{ 'selected' : currentPage === 'overview', 'noSelected' : currentPage !== 'overview' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'overview'" [name]="'briefcase'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'overview'" [name]="'briefcase'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'OVERVIEW.SUMMARY' | translate }}</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide class="w-auto mr-8">
              <div (click)="viewNavclick('news')" *ngIf="headerInfo.hasUpdates" class="appear px-16 py-8 eyelash cursor-pointer"
              [ngClass]="{ 'selected' : currentPage === 'news', 'noSelected' : currentPage !== 'news' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'news'" [name]="'clock'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'news'" [name]="'clock'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'UPDATES.UPDATES' | translate }}</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide *ngIf="detailProject.type === 1 && detailProject.id !== 38" class="w-auto mr-8">
              <div (click)="viewNavclick('monitoring')" *ngIf="headerInfo.hasMonitoring" class="appear px-16 py-8 eyelash cursor-pointer "
              [ngClass]="{ 'selected' : currentPage === 'monitoring', 'noSelected' : currentPage !== 'monitoring' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'monitoring'" [name]="'bar-char-2'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'monitoring'" [name]="'bar-char-2'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'OVERVIEW.METRICS' | translate }}</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide class="w-auto mr-8">
              <div (click)="viewNavclick('documentation')" *ngIf="headerInfo.hasDocumentation" class="appear px-16 py-8 eyelash cursor-pointer "
              [ngClass]="{ 'selected' : currentPage === 'documentation', 'noSelected' : currentPage !== 'documentation' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'documentation'" [name]="'paperclip'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'documentation'" [name]="'paperclip'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">Docs</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide class="w-auto mr-8" *ngIf="detailProject.id !== 38">
              <div (click)="viewNavclick('calendar')" *ngIf="headerInfo.hasCalendar" class="appear px-16 py-8 eyelash cursor-pointer "
              [ngClass]="{ 'selected' : currentPage === 'calendar', 'noSelected' : currentPage !== 'calendar' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'calendar'" [name]="'credit-card'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'calendar'" [name]="'credit-card'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'OVERVIEW.PAY' | translate }}</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide class="w-auto mr-8">
              <div (click)="viewNavclick('marketplace')" *ngIf="headerInfo.hasMarketplace" class="appear px-16 py-8 eyelash cursor-pointer "
              [ngClass]="{ 'selected' : currentPage === 'marketplace', 'noSelected' : currentPage !== 'marketplace' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'marketplace'" [name]="'file'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'marketplace'" [name]="'file'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'MOVEMENTS.NOTICEBOARD' | translate }}</p>
                </div>
              </div>
            </ion-slide>
            <ion-slide *ngIf="detailProject.type === 1" class="w-auto mr-8">
              <div (click)="viewNavclick('meeting')" *ngIf="headerInfo.hasMeetingList" class="appear px-16 py-8 eyelash cursor-pointer "
              [ngClass]="{ 'selected' : currentPage === 'meeting', 'noSelected' : currentPage !== 'meeting' }">
                <div class="flex items-center gap-8">
                  <fun-icon *ngIf="currentPage === 'meeting'" [name]="'coin-stack'" [style]="'stroke-[4px] stroke-[#FF7C92] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <fun-icon *ngIf="currentPage !== 'meeting'" [name]="'coin-stack'" [style]="'stroke-[4px] stroke-eggplant-100/[0.5] hover:cursor-pointer w-24 h-24'"></fun-icon>
                  <p class="font-sans text-xl">{{ 'MEETING.TITLE' | translate }}</p>
                </div>
              </div>
            </ion-slide>
          </ion-slides>
          <div *ngIf="detailProject.type === 1 && detailProject.id !== 38" class="cursor-pointer absolute right-[-24px] top-[14px]" (click)="nextSliderHeader()"><fun-icon  [name]="'arrow-right'" [style]="'w-24 h-24 stroke-[4px] stroke-eggplant-100/[0.5]'"></fun-icon></div>
        </div>
      </div>
    </div>

    <div class="general-container generic">

      <div class="dsktp-none px-[18px] pb-32" *ngIf="project.status > 4 && project.status < 12">
        <fun-page-submenu #pageSubmenu [list]="pageList" (iconClickedEvent)="viewNavclick($event)"></fun-page-submenu>
      </div>

      <div class="span-container">
        <span id="item-name">{{ project.name }}</span>
        <span id="item-id">{{ project.id }}</span>
        <span *ngIf="project.promoter !== undefined" id="item-brand">{{ project.promoter.name }}</span>
        <span id="item-category">Energía Solar</span>
        <span id="item-list-name">{{ statusP }}</span>
        <span id="item-location">{{ project.location }}, ES</span>
        <span id="item-profit">{{ project.profitability | number:'1.0-2':'es' }}</span>
        <span id="item-goal">{{ project.total | number:'1.0-2':'es' }} €</span>
        <span id="item-index">{{ projectPosition }}</span>
        <span id="price-box">{{ amount }}</span>
        <span *ngIf="finishedInvest" id="transaction-id">{{ transactionId }}</span>
      </div>

      <div class="appear px-18 md:px-0 mb-32" *ngIf="currentPage === 'overview' &&
      ((!detailProject.user_can_invest_by_postal_code_now && detailProject.investment_by_postal_code_enabled
      && (detailProject.status === 4 || detailProject.status === 12 || detailProject.status === 3 || detailProject.status === 15))
      || (project.status === 14))">
        <fun-info [data]="infoData" (clickBtnEvent)="openInfoModal()"></fun-info>
      </div>

      <div *ngIf="currentPage === 'overview'" class="row appear px-18 md:px-0">
        <div class="col-8 col-md-12 content appear">
          <div id="about">
            <app-overview-aboutproject-section
              *ngIf="isLoaded && aboutSection"
              [aboutProjectInfo]="aboutProjectInfo"
            ></app-overview-aboutproject-section>
            <div *ngIf="isLoaded && aboutSection && detailProject.status === 3" class="flex items-center justify-center pb-32">
              <fun-new-button [buttonType]="'colorFull'" [size]="'xl'" title="Informarme sobre las novedades del proyecto"
              (eventClick)="stayInformed()" [classExtra]="' w-auto mx-auto '"></fun-new-button>
            </div>
          </div>
          <div #teamB id="teamB">
            <app-overview-sections
              *ngIf="isLoaded && promoterSection && detailProject.status"
              [section]="'constructor'"
              [sectionInfo]="constructorInfo"
              (eventClickViewProject)="viewProjects($event)"
            ></app-overview-sections>
          </div>
          <div class="my-32 relative w-full min-h-[265px] md:min-h-[420px] video-container" *ngIf="isLoaded && id && (id === 103 || id === 110 || id === 111 || id === 118 || detailProject.youtubeUrl)">
            <h2 class="font-sans text-h600 text-eggplant-100/[0.25] pb-40">{{ 'OVERVIEW_PROJECT.ADDITIONAL_INFORMATION' | translate }}</h2>
            <iframe
            *ngIf="id === 103"
            className="w-full min-h-[265px] md:min-h-[420px] aspect-video"
            src="https://www.youtube-nocookie.com/embed/ymz_AZX_Khk?controls=0&modestbranding=1&rel=0&showinfo=0"
            title="Fundeen | Proyecto Global de Movilidad Eléctrica REMO"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            <iframe
            *ngIf="id === 110 || id === 111"
            className="w-full min-h-[265px] md:min-h-[420px] aspect-video"
            src="https://www.youtube.com/embed/XWvVW1-zxjY?si=ZQb04SmGD3adPIUb"
            title="Fundeen | Portfolio MASPV"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            <iframe
            *ngIf="id === 118"
            className="w-full min-h-[265px] md:min-h-[420px] aspect-video"
            src="https://www.youtube-nocookie.com/embed/l0dAIzjPLeo?si=9ISHd5PHTlMnTbk4?controls=0&modestbranding=1&rel=0&showinfo=0"
            title="Fundeen | Proyecto Global de Movilidad Eléctrica REMO"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            <iframe
            *ngIf="detailProject.youtubeUrl"
            className="w-full min-h-[265px] md:min-h-[420px] aspect-video"
            [src]="detailProject.youtubeUrl"
            title="Fundeen | Proyecto Global de Movilidad Eléctrica REMO"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
          </div>
          <div #investors id="investors">
            <app-overview-investors-section *ngIf="isLoaded && ( (investorsInfo.investorLevel.first + investorsInfo.investorLevel.fourth + investorsInfo.investorLevel.second + investorsInfo.investorLevel.third) > 0 )" [investorsInfo]="investorsInfo"></app-overview-investors-section>
          </div>
          <div *ngIf="hasContractSummary" class="appear">
            <app-overview-contract-summary [contractSummary]="project.locale"></app-overview-contract-summary>
          </div>
        </div>
        <div class="col-4 col-md-12 status appear">
          <div>
            <fun-overview-status-skeleton *ngIf="!isLoaded || !hasStatusaside"></fun-overview-status-skeleton>
            <app-overview-status-project
              (eventClickViewBTN)="controlAsideBtn($event)"
              (editInvestEvent)="editInvest()"
              (downloadAllDocumentsEvent)="downloadAllDocuments()"
              (calculateBeneffitsEvent)="scrollToCalculateBeneffits()"
              [info]="statusAside"
              [isFlexibleCompensation]="isFlexibleCompensation"
              *ngIf="isLoaded && hasStatusaside"
            ></app-overview-status-project>
          </div>
        </div>
      </div>

      <div *ngIf="currentPage === 'news'" class="row appear px-18">
        <div class="col-2 col-md-12 navigation"></div>
        <div class="col-8 col-md-12 content-updates">

          <div class="pl-[18px] flex">
            <p class="font-sans text-l text-eggplant-100 pr-12">{{ 'PROJECT_LIST.ORDER_BY' | translate }}</p>
            <fun-filter-originator *ngIf="loadedUpdates" [defaultSelected]="defaultItemSelected" [items]="listOfFilter" (itemselectedEvent)="getFilter($event)"></fun-filter-originator>
          </div>
          <div *ngIf="!loadedUpdates">
            <fun-spinner></fun-spinner>
          </div>

          <app-update-list
            #update
            *ngIf="loadedUpdates"
            [updates]="realUpdates"
            [isInvestor]="isInvestor"
            [isDetail]="true"
            (eventEmitUuid)="downloadDoc($event)"
          ></app-update-list>
        </div>
        <div class="col-2 col-md-12 status"></div>
      </div>
      <div class="row appear px-18" *ngIf="currentPage === 'marketplace' && activateMarketplace">
        <!-- <div *ngIf="loadingMkp">
          <fun-spinner></fun-spinner>
        </div> -->
        <div *ngIf="!hasMarketplace && !loadingMkp">
          <fun-overal-message
            text="{{ 'DASHBOARD.DETAIL_PROJECT.NO_MKP' | translate }}"
            [image]="2" >
          </fun-overal-message>
        </div>
        <fun-project-detail
          #marketplaceRow
          *ngIf="hasMarketplace && !loadingMkp"
          [project]="projectMarketp"
          [hasWalletOk]="isWalletOk"
          (emitCreateBidToOffer)="createBid($event)"
          (eventCreateOffer)="createOffer()"
        ></fun-project-detail>
        <fun-skeleton-list  *ngIf="hasMarketplace && loadingMkp"></fun-skeleton-list>
      </div>
      <div class="row appear px-18 mb-48 md:mb-0" *ngIf="currentPage === 'documentation'">
        <div *ngIf="(!secondaryDocsLits || secondaryDocsLits.length === 0) && !investProjectDocuments">
          <fun-overal-message
          text="{{ 'DASHBOARD.DETAIL_PROJECT.NO_DOCUMENTATION' | translate }}"
          [image]="10" >
          </fun-overal-message>
        </div>
        <app-documentation-list *ngIf="(secondaryDocsLits && secondaryDocsLits.length > 0) || investProjectDocuments"
         #documentation [investProjectDocuments]="investProjectDocuments" [secondaryDocsLits]="secondaryDocsLits"></app-documentation-list>
      </div>
      <div class="row appear payment_calendar_container px-18" *ngIf="currentPage === 'calendar'">
        <fun-calendar-pay-overview [calendarBenefits]="benefitsByMonth"></fun-calendar-pay-overview>
      </div>
      <!---->
      <div class="row appear px-18" *ngIf="currentPage === 'meeting'">
        <fun-overview-meeting [data]="meetingList" [project]="project"></fun-overview-meeting>
      </div>
      <!---->
      <div class="row appear px-18" *ngIf="currentPage === 'monitoring'">
        <ion-grid>
          <p class="lastUpdate font-sans text-eggplant-100/[0.55] text-s" >{{ lastUpdates }}</p>
          <ion-row>
            <div class="card">
              <ion-card-content>
                <p class="font-sans text-m text-eggplant-100/[0.4]">Concepto</p>
                <h3 class="font-sans text-eggplant-100 text-3xl" >{{ 'DASHBOARD.DETAIL_PROJECT.DAILY_PROD' | translate }}</h3>
                <h2 *ngIf="productionToday !== undefined"  class="font-sans text-eggplant-100 text-3xl" title="{{ productionToday | localeNumber }} kW h">
                  {{ productionToday | localeNumber }} kW h
                </h2>
                <ion-skeleton-text *ngIf="productionToday === undefined" style="width: 160px; height: 24px; border-radius: 16px; margin-bottom: 6px;" animated></ion-skeleton-text>
                <ion-skeleton-text *ngIf="productionToday === undefined" style="width: 90px; height: 12px; border-radius: 16px;" animated></ion-skeleton-text>
                <p *ngIf="productionToday !== undefined" class="font-sans text-m text-eggplant-100/[0.4]" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED' | translate }} {{ productionEstimated | localeNumber }} kW h">
                  {{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED' | translate }} {{ productionEstimated | localeNumber }} kW h
                </p>
                <img class="graphic-blue" src="../../../../../assets/images/graphics/daily-production.svg" alt="graphic" />
                <div class="bottom-container blue-graphic-container">
                  <div class="blue-graphic"></div>
                </div>
              </ion-card-content>
            </div>
            <div class="card">
              <ion-card-content>
                <p class="font-sans text-m text-eggplant-100/[0.4]">Concepto</p>
                <h3 class="font-sans text-eggplant-100 text-3xl">{{ 'DASHBOARD.DETAIL_PROJECT.DAILY_INCOME' | translate }}</h3>
                <h2 *ngIf="moneyToday !== undefined" class="font-sans text-eggplant-100 text-3xl" title="{{ moneyToday | localeNumber }} €" >{{ moneyToday | localeNumber }} €</h2>
                <ion-skeleton-text *ngIf="moneyToday === undefined" style="width: 160px; height: 24px; border-radius: 16px; margin-bottom: 6px;" animated></ion-skeleton-text>
                <ion-skeleton-text *ngIf="moneyToday === undefined" style="width: 90px; height: 12px; border-radius: 16px;" animated></ion-skeleton-text>
                <p *ngIf="moneyToday !== undefined" class="font-sans text-m text-eggplant-100/[0.4]" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ moneyEstimated | localeNumber }} €" >
                  {{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ moneyEstimated | localeNumber }} €
                </p>
                <img src="../../../../../assets/images/graphics/daily-pay.svg" alt="graphic" />
                <div class="bottom-container">
                  <div class="green-graphic"></div>
                </div>
              </ion-card-content>
            </div>
          </ion-row>
          <ion-row>
            <div class="card">
              <ion-card-content>
                <p class="font-sans text-m text-eggplant-100/[0.4]">Concepto</p>
                <h3 class="font-sans text-eggplant-100 text-3xl">{{ 'DASHBOARD.DETAIL_PROJECT.ANNUAL_REVENUE' | translate }}</h3>
                <h2 *ngIf="moneyAnnual !== undefined" class="font-sans text-eggplant-100 text-3xl" title="{{ moneyAnnual | localeNumber }} €" >
                  {{ moneyAnnual | localeNumber }} €
                </h2>
                <ion-skeleton-text *ngIf="moneyAnnual === undefined" style="width: 160px; height: 24px; border-radius: 16px; margin-bottom: 6px;" animated></ion-skeleton-text>
                <ion-skeleton-text *ngIf="moneyAnnual === undefined" style="width: 90px; height: 12px; border-radius: 16px;" animated></ion-skeleton-text>
                <p *ngIf="moneyAnnual !== undefined" class="font-sans text-m text-eggplant-100/[0.4]"
                  title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ moneyAnnualEstimated | localeNumber }} €"
                >
                  {{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ moneyAnnualEstimated | localeNumber }} €
                </p>
                <img src="../../../../../assets/images/graphics/yearly-revenues.svg" alt="graphic" />
                <div class="bottom-container">
                  <div class="green-graphic-yearly"></div>
                </div>
              </ion-card-content>
            </div>
            <div class="card">
              <ion-card-content>
                <p class="font-sans text-m text-eggplant-100/[0.4]">Concepto</p>
                <h3 class="font-sans text-eggplant-100 text-3xl">{{ 'DASHBOARD.DETAIL_PROJECT.HISTORICAL' | translate }}</h3>
                <div class="historic-column">
                  <h2 *ngIf="productionHistoric !== undefined" class="font-sans text-eggplant-100 text-3xl" title="{{ productionHistoric | number:'1.0-0':'es' }} kW h" >
                    {{ productionHistoric | number:'1.0-0':'es' }} kW h
                  </h2>
                  <ion-skeleton-text *ngIf="productionHistoric === undefined" style="width: 160px; height: 24px; border-radius: 16px; margin-bottom: 6px;" animated></ion-skeleton-text>
                  <ion-skeleton-text *ngIf="productionHistoric === undefined" style="width: 90px; height: 12px; border-radius: 16px;" animated></ion-skeleton-text>
                  <p *ngIf="productionHistoric !== undefined" class="font-sans text-m text-eggplant-100/[0.4]" title="{{ 'DASHBOARD.DETAIL_PROJECT.PRODUCTION' | translate }}" >
                    {{ 'DASHBOARD.DETAIL_PROJECT.PRODUCTION' | translate }}
                  </p>
                </div>
                <div class="historic-column">
                  <h2 *ngIf="moneyHistoric !== undefined" class="font-sans text-eggplant-100 text-3xl" title="{{ moneyHistoric | localeNumber }} €" >
                    {{ moneyHistoric | localeNumber }} €
                  </h2>
                  <ion-skeleton-text *ngIf="moneyHistoric === undefined" style="width: 160px; height: 24px; border-radius: 16px; margin-bottom: 6px;" animated></ion-skeleton-text>
                  <ion-skeleton-text *ngIf="moneyHistoric === undefined" style="width: 90px; height: 12px; border-radius: 16px;" animated></ion-skeleton-text>
                  <p *ngIf="moneyHistoric !== undefined" class="font-sans text-m text-eggplant-100/[0.4]" title="{{ 'DASHBOARD.DETAIL_PROJECT.INCOME' | translate }}">
                    {{ 'DASHBOARD.DETAIL_PROJECT.INCOME' | translate }}
                  </p>
                </div>
                <div class="bottom-container">
                </div>
              </ion-card-content>
            </div>
          </ion-row>
        </ion-grid>
      </div>
      <!---->
    </div>
  </div>
  <div *ngIf="currentPage === 'overview' && isLoaded && !refreshModalOpened &&
  (detailProject.status === 4 || detailProject.status === 12 || detailProject.status === 15 || detailProject.status === 14 || detailProject.status === 3)" id="calculator" #calculator>
    <fun-overvier-calculator [isFlexibleCompensation]="isFlexibleCompensation" [type]="detailProject.type" [status]="detailProject.status" [id]="detailProject.id" [profitability]="detailProject.profitability" [info]="statusAside" (openInvestEvent)="controlAsideBtn($event)" (calculateHubspotEvent)="changeHuspotStage('calculator', $event)"
    [investors]="(detailProject.inversors_profile.range_0.total + detailProject.inversors_profile.range_1.total + detailProject.inversors_profile.range_2.total + detailProject.inversors_profile.range_3.total)"></fun-overvier-calculator>
  </div>

</div>
