import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'querystring';

@Pipe({
  name: 'powerWatios'
})

export class PowerWatiosPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value !== 0) {
      if ( (this.countDecimals(value) > 0) && ( Math.floor(value) === 0 )) {
        return (value * 1000).toString().replace('.', ',') + ' kW';
      } else {
        if (this.countDecimals(value) === 0) {
          return value + ' ' + ' MW';
        } else {
          return value.toString().replace('.', ',') + ' MW';
        }
      }
    } else {
      return '0 MW';
    }

  }
  countDecimals(value) {
    if (Math.floor(value) == value) { return 0; }
    return value.toString().split('.')[1].length || 0;
  }

}
