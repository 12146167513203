import { Injectable } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class DailyProductionGraphicService {
  constructor(private communicationService: CommunicationService) {}

  getInfo(id) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/graphicDay/production/' + id, null);
  }
}
