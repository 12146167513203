import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-page-submenu',
  templateUrl: './page-submenu.component.html',
  styleUrls: ['./page-submenu.component.scss'],
})
export class PageSubmenuComponent implements OnInit {

  @Output() public iconClickedEvent = new EventEmitter<any>();
  @Input() list: [any];

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.chdRef.detectChanges();
  }

  iconClicked(url) {
    this.iconClickedEvent.emit(url);
    this.chdRef.detectChanges();
  }

}
