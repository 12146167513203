<div class="container">
    <div *ngIf="type === 'date' && createdDateForm" class="flex flex-col gap-y-6">
      <div class="none" [formGroup]='parentForm'>
        <input [ngModel]="value" class="none" #input value="" [formControlName]="controlName" [id]="idInp">
      </div>
      <form [formGroup]="dateForm" class="flex">
        <div class="inp_content dt pr-16">
          <div class="inp_border">
            <label class={{labelClass}} for="">{{'input-date.day' | translate}}</label>
            <input class={{inputDateClass}} (keyup)="checkDateForm($event, 'day')" (focus)="selectDateInput(0)" (focusout)="unselectDateInput(0)" (keypress)="onlyNumberKey($event)"
            maxlength="2" id="day" formControlName="day" #day placeholder="DD" type="text" digitOnly>
          </div>
        </div>
        <div class="inp_content dt pr-16">
          <div class="inp_border">
            <label class={{labelClass}} for="">{{'input-date.month' | translate}}</label>
            <input class={{inputDateClass}} (keyup)="checkDateForm($event, 'month')" (focus)="selectDateInput(1)" (focusout)="unselectDateInput(1)" (keypress)="onlyNumberKey($event)"
            maxlength="2" id="month" formControlName="month" #month type="text" placeholder="MM">
          </div>
        </div>
        <div class="inp_content">
          <div class="inp_border">
            <label class={{labelClass}} for="">{{'input-date.year' | translate}}</label>
            <input class={{inputDateClass}} (keyup)="checkDateForm($event, 'year')" (focus)="selectDateInput(2)" (focusout)="unselectDateInput(2)" (keypress)="onlyNumberKey($event)"
            maxlength="4" id="year" formControlName="year" #year type="text" placeholder="AAAA">
          </div>
        </div>
      </form>
      <label class={{labelErrorClass}} *ngIf="invalidDate">{{'input-date.invalid-date' | translate}}</label>
    </div>

    <div *ngIf="type !== 'date'" [formGroup]='parentForm' class="flex flex-col gap-y-6">
      <label *ngIf="labelName && labelName !== ''" class={{labelClass}}>{{ labelName }}</label>
      <div class="input-container relative" #inputContainer>
        <input class={{inputClass}} *ngIf="isIban && disabled !== true" class="input" (onChange)="changeValue()" [placeholder]="placeholder" #input [type]="type" value="" [formControlName]="controlName"
        [ngModel]="input.value" [id]="idInp" (blur)="selectInput()" (focus)="selectInput()" (focusout)="unselectInput()" [autocomplete]="autocomplete" appFormatIban >
        <input class={{inputClass}} *ngIf="isBIC && disabled !== true" class="input" (onChange)="changeValue()" [placeholder]="placeholder" #input [type]="type" value="" [formControlName]="controlName"
        [ngModel]="input.value" [id]="idInp" (blur)="selectInput()" (focus)="selectInput()" (focusout)="unselectInput()" [autocomplete]="autocomplete">
        <input class={{inputClass}} *ngIf="(!isIban && !isBIC && type !== 'number' && type !== 'date' && type !=='select' && type !== 'invest') || disabled" class="input" (change)="changeValue()" [id]="idInp" (focus)="selectInput()" [autocomplete]="autocomplete"
         [placeholder]="placeholder" #input [type]="type" value="" [formControlName]="controlName" [ngModel]="value" (blur)="selectInput()" (focusout)="unselectInput()">
        <input class={{inputClass}} *ngIf="!isIban && !isBIC && type === 'number' && disabled !== true" class="input" (change)="changeValue()" [id]="idInp" (blur)="selectInput()" (focus)="selectInput()"
         [placeholder]="placeholder" #input type="number" value="" [formControlName]="controlName" [ngModel]="value" (focusout)="unselectInput()" [autocomplete]="autocomplete">
        <input class={{inputClass}} *ngIf="type === 'invest' && disabled !== true" class="input" (change)="changeValue()" [id]="idInp" (focus)="selectInput()" [autocomplete]="autocomplete"
         [placeholder]="placeholder" #input [type]="type" value="" [formControlName]="controlName" [ngModel]="value" (blur)="selectInput()" (focusout)="unselectInput()"
         (keypress)="keypressInput($event)" (keyup)="keyupInput()">
        <ng-select
          class={{inputSelectClass}}
          *ngIf="!isIban && !isBIC && type === 'select' && disabled !== true && controlName !== 'nation' && controlName !== 'residence' && controlName !== 'taxCountry' && controlName !== 'nationalityRepresentative' && controlName !== 'countryRepresentative'"
          [(ngModel)]='selectedItem'
          [items]="items"
          bindValue="id"
          bindLabel="name"
          [clearable]="clearable"
          [searchable]="searchable"
          [formControlName]="controlName"
          [placeholder]="placeholder"
          (change)="changeValue()"
          (open)="selectInput()"
          (close)="unselectInput()"
        ></ng-select>
        <ng-select
          class={{inputSelectClass}}
          *ngIf="!isIban && !isBIC && type === 'select' && disabled !== true && (controlName === 'nation' || controlName === 'residence' || controlName === 'taxCountry' || controlName === 'nationalityRepresentative' || controlName === 'countryRepresentative')"
          [(ngModel)]='selectedItem'
          [clearable]="clearable"
          [searchable]="searchable"
          [formControlName]="controlName"
          [placeholder]="placeholder"
          (change)="changeValue()"
          (open)="selectInput()"
          (close)="unselectInput()">
          <ng-option *ngFor="let item of items" [value]="item.id">
            <img class="flag" [src]="item.flagUrl" width="20">
            <div class="name">{{item.name | titlecase}}</div>
          </ng-option>
        </ng-select>

        <!-- <img class="icons" src="../../../../assets/images/img/small-delete.svg" (click)="clear()"
          *ngIf="borderType === 'error' && type !== 'date' && type !== 'select'">
        <img class="icons" src="../../../../assets/images/img/ok-green.svg"
        *ngIf="type !== 'date' && type !== 'select' && idInp !== 'addressAutocomplete' && getObjectKeys(parentForm.controls[controlName].errors) === null && !this.errorLogin && borderType === 'focused'"> -->
        <!-- <img *ngIf="type === 'date'" class="icons calendar" src="assets/images/icons/calendar.svg"> -->
        <div *ngIf="suffix !== '' " class="absolute right-12 top-[10px]">
          <div *ngIf="suffix === 'password'" class="cursor-pointer" (click)="toggleShow()">
            <fun-icon *ngIf="!showPwd" [name]="'eye'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
            <fun-icon *ngIf="showPwd" [name]="'eye-off'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
          </div>
          <div *ngIf="suffix === 'euro'">
            <p class="text-right font-sans text-eggplant-80 text-l">Euro (€)</p>
          </div>
        </div>
        <div *ngIf="searchMode" class="absolute right-12 top-[14px] cursor-pointer">
          <fun-icon (click)="search()" [name]="'search'" [style]="'w-16 h-16 stroke-[2px] stroke-[#29141F]'"></fun-icon>
        </div>
      </div>
      <div *ngIf="!parentForm.valid && isSubmit">
        <p class="error-message" *ngFor="let eachError of (getObjectKeys(parentForm.controls[controlName].errors))">
          {{errorMessages[eachError]}}
        </p>
      </div>
    </div>
  </div>
