<div class="general-container">
  <div class="title">
      <h3 class="font-sans text-eggplant-100 text-xl">{{ data.name }}</h3>
      <p class="font-sans text-gray-25 text-h400">{{ 'invest.Popup.missing' | translate }} {{ data.objective - data.contributed | localeNumber }} €</p>
      <img src="../../assets/images/img/down.svg" alt="arrow down">
  </div>
  <div class="graphic-container">
      <div class="bar-grap-container">
          <div class="bar-container">
              <div [ngStyle]="{ width: percentInvested + '%' }" class="barProgress linear-gradient-2"></div>
          </div>
      </div>
      <div class="info">
          <div>
              <p class="font-sans text-eggplant-100/[0.25] text-m">{{ 'invest.Popup.contributed' | translate }}</p>
              <h4 class="font-sans text-eggplant-100 text-xl">{{ data.contributed | localeNumber }} €</h4>
          </div>
          <div>
              <p class="font-sans text-eggplant-100/[0.25] text-m">{{ 'invest.Popup.objective' | translate }}</p>
              <h4 class="font-sans text-eggplant-100 text-xl">{{ data.objective | localeNumber }} €</h4>
          </div>
      </div>
  </div>
</div>