<div class="general-container" [class]="side">
  <!--<app-graphics-nav [page]="'yearly-revenues'" initial="2"></app-graphics-nav>-->

  <ion-slides class="nav-slider" pager="false" [options]="navSlideOpts" id="resalLinesC" #resalLinesC>
    <ion-slide [style.background-color]="inTodayColor" (click)="resaltLine('in-today')">
      <div>
        <p>{{ 'DASHBOARD.DAILY_INCOME.INC' | translate }}</p>
        <p>{{ realMoment }}</p>
        <h2 class="acum" title="{{ ingToday | number: '1.0-2':'es' }} €">{{ ingToday | number: '1.0-2':'es' }} €</h2>
      </div>
    </ion-slide>
    <ion-slide [style.background-color]="inEstimColor" (click)="resaltLine('in-estim')">
      <div>
        <p>{{ 'DASHBOARD.DAILY_INCOME.ESTIMATED_INC' | translate }}</p>
        <h2 title="{{ ingEstim | number: '1.0-2':'es' }} €">{{ ingEstim | number: '1.0-2':'es' }} €</h2>
      </div>
    </ion-slide>
    <ion-slide [style.background-color]="prTodayColor" (click)="resaltLine('pr-today')">
      <div>
        <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.PRICE' | translate }}</p>
        <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.REAL_PRICE' | translate }}</p>
        <p *ngIf="graphic !== 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.ACCUMULATED' | translate }}</p>
        <h2 title="{{ realPrice | number: '1.0-2':'es' }} €">{{ realPrice | number: '1.0-2':'es' }} €</h2>
      </div>
    </ion-slide>
    <ion-slide [style.background-color]="prEstimColor" (click)="resaltLine('pr-estim')">
      <div>
        <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.PRICE' | translate }}</p>
        <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.ESTIMATED_PRICE' | translate }}</p>
        <p *ngIf="graphic !== 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.ACCUMULATED_EST' | translate }}</p>
        <h2 class="acum" title="{{ estimatedPrice | number: '1.0-2':'es' }} €">{{ estimatedPrice | number: '1.0-2':'es' }} €</h2>
      </div>
    </ion-slide>
  </ion-slides>

  <nav #navResaltLinesC id="navResaltLinesC">
    <div [style.background-color]="inTodayColor" (click)="resaltLine('in-today')">
      <p>{{ 'DASHBOARD.DAILY_INCOME.INC' | translate }}</p>
      <p>{{ realMoment }}</p>
      <h2 class="acum" title="{{ ingToday | number: '1.0-2':'es' }} €">{{ ingToday | number: '1.0-2':'es' }} €</h2>
    </div>
    <div [style.background-color]="inEstimColor" (click)="resaltLine('in-estim')">
      <p>{{ 'DASHBOARD.DAILY_INCOME.ESTIMATED_INC' | translate }}</p>
      <h2 class="acum" title="{{ ingEstim | number: '1.0-2':'es' }} €">{{ ingEstim | number: '1.0-2':'es' }} €</h2>
    </div>
    <div [style.background-color]="prTodayColor" (click)="resaltLine('pr-today')">
      <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.PRICE' | translate }}</p>
      <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.REAL_PRICE' | translate }}</p>
      <p *ngIf="graphic !== 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.ACCUMULATED' | translate }}</p>
      <h2 [ngClass]="{ acum: graphic !== 'graphicDay' }" title="{{ realPrice | number: '1.0-2':'es' }} €">
        {{ realPrice | number: '1.0-2':'es' }} €
      </h2>
    </div>
    <div [style.background-color]="prEstimColor" (click)="resaltLine('pr-estim')">
      <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.PRICE' | translate }}</p>
      <p *ngIf="graphic === 'graphicDay'">{{ 'DASHBOARD.DAILY_INCOME.ESTIMATED_PRICE' | translate }}</p>
      <p *ngIf="graphic !== 'graphicDay'" class="acum-txt">{{ 'DASHBOARD.DAILY_INCOME.ACCUMULATED_EST' | translate }}</p>
      <h2 class="acum" title="{{ estimatedPrice | number: '1.0-2':'es' }} €">{{ estimatedPrice | number: '1.0-2':'es' }} €</h2>
    </div>
  </nav>

  <div class="full-graphic">
    <div class="label">
      <div
        *ngIf="inToday[indexClicked] || inEstim[indexClicked] || prToday[indexClicked] || prEstim[indexClicked]"
        class="legendContainer"
      >
        <p
          title="{{ inToday[indexClicked] | number: '1.0-2':'es' }} €"
          *ngIf="inToday[indexClicked]"
          class="inTodayLegend"
          [ngClass]="{ inTodayNoSelected: inTodayClicked === false }"
        >
          {{ inToday[indexClicked] | number: '1.0-2':'es' }} €
        </p>
        <p
          title="{{ inEstim[indexClicked] | number: '1.0-2':'es' }} €"
          *ngIf="inEstim[indexClicked]"
          class="inEstimLegend"
          [ngClass]="{ inEstimNoSelected: inEstimClicked === false }"
        >
          {{ inEstim[indexClicked] | number: '1.0-2':'es' }} €
        </p>
        <p
          title="{{ prToday[indexClicked] | number: '1.0-2':'es' }} €"
          *ngIf="prToday[indexClicked]"
          class="prTodayLegend"
          [ngClass]="{ prTodaySelected: prTodayClicked === false }"
        >
          {{ prToday[indexClicked] / 1000 | number: '1.0-2':'es' }} €
        </p>
        <p
          title="{{ prEstim[indexClicked] | number: '1.0-2':'es' }} €/MW"
          *ngIf="prEstim[indexClicked]"
          class="prEstimLegend"
          [ngClass]="{ prEstimSelected: prEstimClicked === false }"
        >
          {{ prEstim[indexClicked] / 1000 | number: '1.0-2':'es' }} €/MW
        </p>
      </div>
    </div>

    <div class="no-margin">
      <ion-spinner *ngIf="!loaded" name="lines"></ion-spinner>
      <!--<div class="center-line"></div>-->
      <div #chartContainer class="canvas-container">
        <div class="scroll-container">
          <div class="graph-container">
            <canvas #lineChart id="myChart">{{ chart }}</canvas>
          </div>
        </div>
      </div>
    </div>

    <ion-slides class="weather-slider" pager="false" [options]="slideOpts" (ionSlideTap)="changePoint()" #sliderWeather>
      <ion-slide *ngFor="let hour of hours">
        <div (click)="getRealMonth(hour.time, indexClicked)" [ngClass]="{ clicked: hour.index === indexClicked }">
          <p>{{ hour.time }}</p>
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</div>
