import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy, HostListener, Input, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
import { IonSlides } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { DailyProductionGraphicService } from './daily-production-graphic.service';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-daily-production-graphic',
  templateUrl: './daily-production-graphic.component.html',
  styleUrls: ['./daily-production-graphic.component.scss'],
})
export class DailyProductionGraphicComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('lineChart', { static: true }) private chartRef;
  @ViewChild('chartContainer', { static: true }) private chartContainer;
  @ViewChild('sliderWeather', { static: true }) sliderWeather: IonSlides;

  @ViewChild('navResaltLinesC', { static: true }) private navResaltLinesC;
  @ViewChild('resalLinesC', { static: true }) private resalLinesC;
  @Input('side') side;

  public context: CanvasRenderingContext2D;
  chart: any;
  ctx: any;
  idProject: string;
  projectName: string;
  power: any;
  isClicked = false;
  technology: any;
  location: any;
  data: any;
  indexClicked: number;
  tec: string;
  loaded;

  projectData: any;
  labels = [];
  pointsContainer = [];
  estimRecData = [];
  estimProdData = [];
  realProdData = [];
  lineColor: string;
  recEstimColor: string;
  prodRealColor = '#225EBF';
  prodEstimColor = '#2E7EFF';

  recEstimClicked = true;
  prodRealClicked = true;
  prodEstimClicked = true;
  timesClicked = 0;

  projectInfo = [];

  totalReal: number;
  totalEstim: number;
  totalRec: number;

  navSlideOpts = {
    initialSlide: 0,
    speed: 1000,
    width: 288,
    effect: 'slide', // slide, fade, cube, coverflow, flip
    slidesPerView: 3,
    grabCursor: true,
    slideToClickedSlide: true,
    freeMode: true,
    centeredSlides: true,
  };

  slideOpts: any;

  constructor(private route: ActivatedRoute, private dailyProductionservice: DailyProductionGraphicService, public chdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.controlSliderWidth();
    this.loaded = false;
    registerLocaleData(es);

    window.addEventListener('scroll', this.scroll, true);

    this.route.queryParams.subscribe((params) => {
      this.getData(params['id']).then(() => {
        this.idProject = params['id'];
        this.projectName = params['name'];
        this.power = params['power'];
        this.technology = params['type'];
        this.location = params['location'];

        this.data = {
          name: this.projectName,
          power: this.power,
          technology: this.technology,
          location: this.location,
        };

        this.sliderWeather.ionSlideTransitionEnd.subscribe(() => this.ionSlideTransitionEnd());
      });
    });
    this.controlProjectColors();
  }
  ngAfterViewInit() {}
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.controlSliderWidth();
  }

  getData(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dailyProductionservice.getInfo(id).subscribe((resp) => {
        this.loaded = true;
        this.projectData = resp;
        let ind = 0;
        this.projectData.forEach((data) => {
          this.pointsContainer.push(0);
          this.labels.push('');
          if (data.production !== undefined) {
            this.realProdData.push(data.production);
          }
          this.estimProdData.push(data.production_estimate);
          this.estimRecData.push(data.resource_estimate);
          const weatherInfo = {
            index: ind,
            weather: data.weather,
            temperature: data.temperature,
            time: data.time,
          };
          this.projectInfo.push(weatherInfo);
          ind++;
        });
        this.totalReal = this.realProdData.reduce((a, b) => a + b, 0);
        this.totalEstim = this.estimProdData.reduce((a, b) => a + b, 0);
        this.totalRec = this.estimRecData.reduce((a, b) => a + b, 0);
        let currentMoment;
        if (this.realProdData.length === 0) {
          currentMoment = 0;
        } else {
          currentMoment = this.realProdData.length - 1;
        }
        this.sliderWeather.getActiveIndex().then((index: number) => {
          // setTimeout(() => this.createGrap(), 200);
          this.createGrap();
          // setTimeout(() => this.activePoints(currentMoment), 200);
          this.activePoints(currentMoment);

          resolve(true);
        });
        this.chdRef.detectChanges();
        //this.sliderWeather.ionSlideTransitionEnd.subscribe(() => this.ionSlideTransitionEnd());
      }),
        (error) => reject();
    });
  }
  ionSlideTransitionEnd() {
    this.sliderWeather.getActiveIndex().then((index: number) => {
      if (!this.isClicked) {
        this.activePoints(index);
      }
      this.isClicked = false;
    });
  }
  controlProjectColors() {
    if (this.technology === '1' || this.technology === undefined) {
      this.tec = 'solar';
      this.lineColor = '#FEA100';
    } else {
      this.tec = 'eolic';
      this.lineColor = '#7EA7BF';
    }
    this.recEstimColor = this.lineColor;
  }

  changePoint() {
    this.sliderWeather.getActiveIndex().then((index: number) => {
      this.activePoints(index);
    });
    this.isClicked = true;
  }
  createGrap() {
    const canvas: any = document.getElementById('myChart');
    this.ctx = canvas.getContext('2d');
    const gradientFill = this.ctx.createLinearGradient(20, 20, 20, 300);
    gradientFill.addColorStop(0, 'rgba(34, 94, 191, 0.12)');
    gradientFill.addColorStop(1, 'rgba(34, 94, 191, 0)');
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.estimProdData,
            borderColor: this.prodEstimColor,
            borderWidth: 4,
            backgroundColor: gradientFill,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointHitRadius: 20,
            pointHoverBorderWidth: 4,
            pointBackgroundColor: 'white',
            fill: true,
            yAxisID: 'A',
          },
          {
            data: this.estimRecData,
            borderColor: this.recEstimColor,
            borderWidth: 4,
            backgroundColor: gradientFill,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointHitRadius: 20,
            pointHoverBorderWidth: 4,
            pointBackgroundColor: 'white',
            fill: true,
            yAxisID: 'B',
          },
          {
            data: this.realProdData,
            borderColor: this.prodRealColor,
            borderWidth: 4,
            backgroundColor: gradientFill,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointHitRadius: 20,
            pointHoverBorderWidth: 4,
            pointBackgroundColor: 'white',
            fill: true,
            yAxisID: 'A',
          },
        ],
      },
      options: {
        onClick: (evt, item) => {
          this.isClicked = true;
          this.onChartClick(item[0]);
        },
        /*onHover: (evt, item) => {
          this.isClicked = true;
          this.onChartClick(item[0]);
        },*/
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        animation: true,
        responsive: false,
        maintainAspectRatio: true,

        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false,
              ticks: {
                min: 0,
                max: 10,
                stepWidth: 15,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              display: false,
              ticks: {
                min: -1,
                //max: 25,
                //stepWidth: 15
              },
            },
            {
              id: 'B',
              display: false,
              ticks: {
                //min: 0,
                //max: 25,
                //stepWidth: 15
              },
            },
          ],
        },
      },
    });
    this.chdRef.detectChanges();
  }
  activePoints(n) {
    const elements = this.chart.getDatasetMeta(0);
    const element = elements.data[n];
    this.onChartClick(element);
  }

  scroll = (event): void => {
    const scrollFromLeft = this.chartContainer.nativeElement.scrollLeft;
    const graphicWidth = this.chartRef.nativeElement.offsetWidth;
    const pointDistance = graphicWidth / (this.estimProdData.length - 1);
    const containerWidth = this.chartContainer.nativeElement.offsetWidth;
    const middle = containerWidth / 2;

    let goToM;
    let i: number;
    let indexN;
    const distances = [];

    for (i = 0; i < this.estimProdData.length; i++) {
      if (containerWidth < 775) {
        goToM = middle + i * pointDistance - (containerWidth - 375);
      } else {
        goToM = middle + i * pointDistance - (containerWidth - 500);
      }
      const distance = Math.abs(scrollFromLeft - goToM);
      distances.push(distance);
    }
    indexN = distances.indexOf(Math.min(...distances));
    this.sliderWeather.slideTo(indexN);
  };
  onChartClick(index) {
    if (index && index !== undefined) {
      this.indexClicked = index._index;
      this.sliderWeather.slideTo(index._index);
      const indexPoint = index._index;
      const containerWidth = this.chartContainer.nativeElement.offsetWidth;
      const graphicWidth = this.chartRef.nativeElement.offsetWidth;
      const pointDistance = graphicWidth / (this.estimProdData.length - 1);

      const middle = containerWidth / 2;
      let goToM;
      if (containerWidth < 775) {
        if (window.innerWidth > 995) {
          goToM = middle + indexPoint * pointDistance - (containerWidth - 375) + 2;
        } else {
          goToM = middle + indexPoint * pointDistance - (containerWidth - 375) + 1;
        }
        // goToM = middle + indexPoint * pointDistance - (containerWidth - 375);
      } else {
        goToM = middle + indexPoint * pointDistance - (containerWidth - 500) + 2;
      }
      this.chartContainer.nativeElement.scrollTo({ left: goToM, behavior: 'smooth' });

      let i: number;
      for (i = 0; i < this.pointsContainer.length; i++) {
        if (i === index._index && index._index !== 0 && index._index !== this.pointsContainer.length - 1) {
          this.chart.data.datasets[0].pointRadius[i] = 4;
        } else {
          this.chart.data.datasets[0].pointRadius[i] = 0;
        }
      }
      setTimeout(() => this.chart.update(), 5);
    }
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    const elements = event.path;
    let is;
    if (
      elements[1].id === this.navResaltLinesC.nativeElement.id ||
      elements[2].id === this.navResaltLinesC.nativeElement.id ||
      elements[3].id === this.navResaltLinesC.nativeElement.id ||
      elements[4].id === 'resalLinesC' ||
      elements[3].id === 'resalLinesC' ||
      elements[2].id === 'resalLinesC' ||
      elements[1].id === 'resalLinesC'
    ) {
      is = true;
    } else {
      this.chart.data.datasets[1].borderColor = this.lineColor;
      this.recEstimColor = this.lineColor;
      this.chart.data.datasets[2].borderColor = '#225EBF';
      this.prodRealColor = '#225EBF';
      this.chart.data.datasets[0].borderColor = '#2E7EFF';
      this.prodEstimColor = '#2E7EFF';
      this.chart.update();
      this.detectLine('outside');
    }
  }
  resaltLine(p) {
    setTimeout(() => this.detectLine(p), 200);
  }
  detectLine(p) {
    if (p === 'outside') {
      this.timesClicked = 0;
      this.recEstimClicked = true;
      this.prodRealClicked = true;
      this.prodEstimClicked = true;
    } else {
      this.timesClicked++;
    }
    if (this.timesClicked === 1) {
      switch (p) {
        case 'prod-real':
          this.isClicked = true;
          this.recEstimClicked = false;
          this.prodRealClicked = true;
          this.prodEstimClicked = false;
          this.activePoints(this.realProdData.length - 1);
          this.chart.data.datasets[1].borderColor = 'rgba(254, 161, 0, 0.2)';
          this.chart.data.datasets[0].borderColor = 'rgba(46, 126, 255, 0.2)';
          this.recEstimColor = 'rgba(254, 161, 0, 0.2)';
          this.prodEstimColor = 'rgba(46, 126, 255, 0.2)';
          break;
        case 'prod-estim':
          this.recEstimClicked = false;
          this.prodRealClicked = false;
          this.prodEstimClicked = true;
          this.chart.data.datasets[1].borderColor = 'rgba(254, 161, 0, 0.2)';
          this.chart.data.datasets[2].borderColor = 'rgba(34, 94, 191, 0.2)';
          this.recEstimColor = 'rgba(254, 161, 0, 0.2)';
          this.prodRealColor = 'rgba(34, 94, 191, 0.2)';
          break;
        case 'rec-estim':
          this.recEstimClicked = true;
          this.prodRealClicked = false;
          this.prodEstimClicked = false;
          this.chart.data.datasets[2].borderColor = 'rgba(34, 94, 191, 0.2)';
          this.chart.data.datasets[0].borderColor = 'rgba(46, 126, 255, 0.2)';
          this.prodEstimColor = 'rgba(46, 126, 255, 0.2)';
          this.prodRealColor = 'rgba(34, 94, 191, 0.2)';
          break;
      }
    } else if (this.timesClicked > 1) {
      const arr = [this.prodRealClicked, this.prodEstimClicked, this.recEstimClicked];
      const numbTrue = arr.filter((item) => item === true).length;
      switch (p) {
        case 'prod-real':
          if (this.prodRealClicked === true && numbTrue > 1) {
            this.prodRealClicked = false;
            this.chart.data.datasets[2].borderColor = 'rgba(34, 94, 191, 0.2)';
            this.prodRealColor = 'rgba(34, 94, 191, 0.2)';
          } else if (!this.prodRealClicked) {
            this.prodRealClicked = true;
            this.chart.data.datasets[2].borderColor = '#225EBF';
            this.prodRealColor = '#225EBF';
          }
          break;
        case 'prod-estim':
          if (this.prodEstimClicked === true && numbTrue > 1) {
            this.prodEstimClicked = false;
            this.chart.data.datasets[0].borderColor = 'rgba(46, 126, 255, 0.2)';
            this.prodEstimColor = 'rgba(46, 126, 255, 0.2)';
          } else if (!this.prodEstimClicked) {
            this.prodEstimClicked = true;
            this.chart.data.datasets[0].borderColor = '#2E7EFF'; //in-estim
            this.prodEstimColor = '#2E7EFF';
          }
          break;
        case 'rec-estim':
          if (this.recEstimClicked === true && numbTrue > 1) {
            this.recEstimClicked = false;
            this.chart.data.datasets[1].borderColor = 'rgba(254, 161, 0, 0.2)';
            this.recEstimColor = 'rgba(254, 161, 0, 0.2)';
          } else if (!this.recEstimClicked) {
            this.recEstimClicked = true;
            this.chart.data.datasets[1].borderColor = this.lineColor; //pr-today
            this.recEstimColor = this.lineColor;
          }
          break;
      }
    }
    this.chart.update();
  }
  controlSliderWidth() {
    const currentWidth = window.screen.width;
    let slidesNumber: number;
    if (currentWidth > 992) {
      slidesNumber = 8;
    } else {
      if (currentWidth <= 992 && currentWidth > 878) {
        slidesNumber = 16;
      } else {
        if (currentWidth <= 878 && currentWidth > 770) {
          slidesNumber = 14;
        } else {
          if (currentWidth <= 770 && currentWidth > 670) {
            slidesNumber = 11;
          } else {
            if (currentWidth <= 670 && currentWidth > 550) {
              slidesNumber = 9;
            } else {
              if (currentWidth <= 550 && currentWidth > 490) {
                slidesNumber = 8;
              } else {
                if (currentWidth <= 490 && currentWidth > 430) {
                  slidesNumber = 7;
                } else {
                  if (currentWidth <= 430 && currentWidth > 370) {
                    slidesNumber = 6;
                  } else {
                    slidesNumber = 5;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.slideOpts = {
      initialSlide: 7,
      speed: 400,
      effect: 'slide',
      slidesPerView: slidesNumber, // Este dato tiene  Que ser dinamico y cambiar conforme cambia el ancho
      grabCursor: true,
      slideToClickedSlide: true,
      freeMode: true,
      centeredSlides: true,
      watchSlidesProgress: true,
    };
  }
  ngOnDestroy() {
    //this.chart.clear();
    window.removeEventListener('scroll', this.scroll, true);
  }
}
