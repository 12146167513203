import { Component, ViewChild, OnInit, AfterViewInit, HostListener, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';
import { IonSlides } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { IncomeGraphicService } from './income-graphic.service';

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-income-graphic',
  templateUrl: './income-graphic.component.html',
  styleUrls: ['./income-graphic.component.scss'],
})
export class IncomeGraphicComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('lineChart', { static: true }) private chartRef;
  @ViewChild('chartContainer', { static: true }) private chartContainer;
  @ViewChild('sliderWeather', { static: true }) sliderWeather: IonSlides;

  @ViewChild('navResaltLinesC', { static: true }) private navResaltLinesC;
  @ViewChild('resalLinesC', { static: true }) private resalLinesC;
  @Input('side') side;
  @Input('graphic') graphic;

  public context: CanvasRenderingContext2D;
  // dataReady = false;
  chart: any;
  ctx: any;
  idProject: string;
  projectName: string;
  power: any;
  isClicked = false;
  technology: any;
  location: any;
  data: any;
  indexClicked: number;
  tec: string;
  realMoment: string;
  loaded;

  timesClicked = 0;

  projectData: any;
  pointsContainer = [];
  labels: any = [];
  inToday: any = [];
  inEstim: any = [];
  prToday: any = [];
  prEstim: any = [];

  inTodayClicked = true;
  inEstimClicked = true;
  prTodayClicked = true;
  prEstimClicked = true;

  inTodayColor = '#188B18';
  inEstimColor = '#4BD262';
  prTodayColor = '#6CE580';
  prEstimColor = ' #A0A0A0';
  hours = [];

  ingToday;
  ingEstim;
  realPrice;
  estimatedPrice;

  navSlideOpts = {
    initialSlide: 0,
    speed: 1000,
    width: 288,
    effect: 'slide', // slide, fade, cube, coverflow, flip
    slidesPerView: 3,
    grabCursor: true,
    slideToClickedSlide: true,
    freeMode: true,
    centeredSlides: true,
  };

  slideOpts: any;

  constructor(
    private incomeService: IncomeGraphicService,
    private route: ActivatedRoute,
    private utils: UtilsService,
    public chdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.controlSliderWidth();
    this.loaded = false;
    registerLocaleData(es);
    window.addEventListener('scroll', this.scroll, true);
    this.route.queryParams.subscribe((params) => {
      this.idProject = params['id'];
      this.getData(params['id']);
      this.projectName = params['name'];
      this.power = params['power'];
      this.technology = params['type'];
      this.location = params['location'];
      this.data = {
        name: this.projectName,
        power: this.power,
        technology: this.technology,
        location: this.location,
      };
    });
  }
  ngAfterViewInit() {
    this.sliderWeather.ionSlideTransitionEnd.subscribe(() => this.ionSlideTransitionEnd());
    this.getGeneralInfo();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.controlSliderWidth();
  }
  async getData(id) {
    this.incomeService.getInfo(id, this.graphic).subscribe((resp) => {
      this.loaded = true;
      this.projectData = resp;
      if (this.graphic === 'graphicYear') {
        this.projectData.reverse();
      }
      let ind = 0;
      this.projectData.forEach((data) => {
        this.pointsContainer.push(0);
        this.labels.push('');
        if (data.incomes !== undefined) {
          this.inToday.push(this.utils.roundNumber(data.incomes));
        }
        if (data.incomes_estimate !== undefined) {
          this.inEstim.push(this.utils.roundNumber(data.incomes_estimate));
        }
        if (data.pool_price !== undefined) {
          this.prToday.push(this.utils.roundNumber(data.pool_price));
        }
        this.prEstim.push(this.utils.roundNumber(data.pool_price_estimate));
        let times;
        if (data.month !== undefined) {
          times = this.incomeService.parseMonth(data.month);
          this.realMoment = this.incomeService.parseMonth(data.month);
        } else {
          this.realMoment = 'hoy';
          times = data.time + ' : 00';
        }
        const timeInfo = {
          index: ind,
          time: times,
        };
        ind++;
        this.hours.push(timeInfo);
      });
      if (this.graphic === 'graphicYear') {
        this.ingEstim = this.utils.roundNumber(this.inEstim[this.inEstim.length - 1]);
      }
      let currentMoment;
      if (this.inToday.length === 0) {
        currentMoment = 0;
      } else {
        currentMoment = this.inToday.length - 1;
      }
      this.sliderWeather.getActiveIndex().then((index: number) => {
        //setTimeout(() => this.createGrap(), 5000);
        this.createGrap();
        //setTimeout(() => this.activePoints(currentMoment), 5000);
        this.activePoints(currentMoment);
      });
      //this.sliderWeather.ionSlideTransitionEnd.subscribe(() => this.ionSlideTransitionEnd());
      this.chdRef.detectChanges();
    });
  }
  async getGeneralInfo() {
    if (this.graphic === 'graphicYear') {
      this.incomeService.getProjectAnnual(this.idProject).subscribe((resp) => {
        this.ingToday = this.utils.roundNumber(resp.moneyMonth);
        // this.ingEstim = resp.moneyMonthEstimated;
        this.realPrice = this.utils.roundNumber(resp.moneyAnnual);
        this.estimatedPrice = this.utils.roundNumber(resp.moneyAnnualEstimated);
      });
    } else {
      this.incomeService.getProjectTodayProduction(this.idProject).subscribe((resp) => {
        this.ingToday = this.utils.roundNumber(resp.money);
        this.ingEstim = this.utils.roundNumber(resp.moneyEstimated);
        this.realPrice = this.utils.roundNumber(resp.price);
        this.estimatedPrice = this.utils.roundNumber(resp.priceEstimated);
      });
    }
  }
  ionSlideTransitionEnd() {
    this.sliderWeather.getActiveIndex().then((index: number) => {
      if (!this.isClicked) {
        this.activePoints(index);
      }
      this.isClicked = false;
    });
  }

  changePoint() {
    this.sliderWeather.getActiveIndex().then((index: number) => {
      this.activePoints(index);
    });
    this.isClicked = true;
  }
  async createGrap() {
    const canvas: any = document.getElementById('myChart');
    this.ctx = canvas.getContext('2d');
    this.roudCorners();
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            type: 'line',
            data: this.prToday,
            borderColor: '#6CE580', //this.prodRealColor,
            borderWidth: 4,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointHitRadius: 20,
            pointHoverBorderWidth: 4,
            pointBackgroundColor: 'white',
            fill: false,
            yAxisID: 'B',
          },
          {
            type: 'bar',
            data: this.inToday,
            borderWidth: 0,
            backgroundColor: '#188B18', //this.prodEstimColor,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointBackgroundColor: 'white',
            fill: true,
            yAxisID: 'A',
          },
          {
            type: 'bar',
            data: this.inEstim,
            borderWidth: 0,
            backgroundColor: '#4BD262', //this.recEstimColor,
            pointRadius: this.pointsContainer,
            pointHoverRadius: 4,
            pointBackgroundColor: 'white',
            fill: true,
            yAxisID: 'A',
          },
          {
            type: 'line',
            data: this.prEstim,
            borderColor: ' #A0A0A0',
            borderWidth: 2,
            pointHitRadius: 0,
            lineDashType: 'line',
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 0,
            pointBackgroundColor: 'white',
            fill: false,
            yAxisID: 'B',
          },
        ],
      },
      options: {
        onClick: (evt, item) => {
          this.isClicked = true;
          this.onChartClick(item[0]);
        },
        /*onHover: (evt, item) => {
          this.isClicked = true;
          this.onChartClick(item[0]);
        },*/
        barValueSpacing: 130,
        barDatasetSpacing: 30,
        curvature: 1,
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        animation: true,
        responsive: false, //false,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barThickness: 12, // number (pixels) or 'flex'
              maxBarThickness: 8, // number (pixels),
              barValueSpacing: 62,
              barDatasetSpacing: 62,
              display: false,
              //categoryPercentage: 15.0,
              //barPercentage: 15.0,
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              display: false,
              ticks: {
                min: 0,
              },
            },
            {
              display: false,
              id: 'B',
              type: 'linear',
              position: 'right',
            },
          ],
        },
      },
    });
  }
  roudCorners() {
    Chart.elements.Rectangle.prototype.draw = function () {
      const ctx = this._chart.ctx;
      const vm = this._view;
      let left, right, top, bottom, signX, signY, borderSkipped, radius;
      let borderWidth = vm.borderWidth;
      const cornerRadius = 20;

      if (!vm.horizontal) {
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      if (borderWidth) {
        const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        const halfStroke = borderWidth / 2;
        const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;
      const corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
      ];

      const borders = ['bottom', 'left', 'top', 'right'];
      let startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      }

      let corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (let i = 1; i < 4; i++) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId === 4) {
          nextCornerId = 0;
        }

        const nextCorner = cornerAt(nextCornerId);

        const width = corners[2][0] - corners[1][0];
        const height = corners[0][1] - corners[1][1];
        const x = corners[1][0];
        const y = corners[1][1];

        let radius = cornerRadius;

        if (radius > height / 2) {
          radius = height / 2;
        }
        if (radius > width / 2) {
          radius = width / 2;
        }
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
    };
  }
  activePoints(n) {
    const elements = this.chart.getDatasetMeta(0);
    const element = elements.data[n];
    this.onChartClick(element);
  }

  scroll = (event): void => {
    const scrollFromLeft = this.chartContainer.nativeElement.scrollLeft;
    const graphicWidth = this.chartRef.nativeElement.offsetWidth;
    const pointDistance = graphicWidth / (this.prEstim.length - 1);
    const containerWidth = this.chartContainer.nativeElement.offsetWidth;
    const middle = containerWidth / 2;

    let goToM;
    let i: number;
    let indexN;
    const distances = [];

    for (i = 0; i < this.prEstim.length; i++) {
      if (containerWidth < 775) {
        goToM = middle + i * pointDistance - (containerWidth - 375);
      } else {
        goToM = middle + i * pointDistance - (containerWidth - 500);
      }
      const distance = Math.abs(scrollFromLeft - goToM);
      distances.push(distance);
    }
    indexN = distances.indexOf(Math.min(...distances));
    this.sliderWeather.slideTo(indexN);
  };
  onChartClick(index) {
    if (index && index !== undefined) {
      this.indexClicked = index._index;
      this.sliderWeather.slideTo(index._index);
      const indexPoint = index._index;
      const containerWidth = this.chartContainer.nativeElement.offsetWidth;
      const graphicWidth = this.chartRef.nativeElement.offsetWidth;
      const pointDistance = graphicWidth / (this.prEstim.length - 1);

      const middle = containerWidth / 2;
      let goToM;
      if (containerWidth < 775) {
        if (window.innerWidth > 995) {
          goToM = middle + indexPoint * pointDistance - (containerWidth - 375) - 5;
        } else {
          goToM = middle + indexPoint * pointDistance - (containerWidth - 375) - 5;
        }
      } else {
        goToM = middle + indexPoint * pointDistance - (containerWidth - 500) - 5;
      }
      this.chartContainer.nativeElement.scrollTo({ left: goToM, behavior: 'smooth' });

      let i: number;
      for (i = 0; i < this.pointsContainer.length; i++) {
        if (i === index._index) {
          this.chart.data.datasets[0].pointRadius[i] = 4;
        } else {
          this.chart.data.datasets[0].pointRadius[i] = 0;
        }
      }
      setTimeout(() => this.chart.update(), 5);
    }
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    const elements = event.path;
    let is;
    if (
      elements[1].id === this.navResaltLinesC.nativeElement.id ||
      elements[2].id === this.navResaltLinesC.nativeElement.id ||
      elements[3].id === this.navResaltLinesC.nativeElement.id ||
      elements[4].id === 'resalLinesC' ||
      elements[3].id === 'resalLinesC' ||
      elements[2].id === 'resalLinesC' ||
      elements[1].id === 'resalLinesC'
    ) {
      is = true;
    } else {
      this.chart.data.datasets[0].borderColor = '#6CE580'; //pr-today
      this.prTodayColor = '#6CE580';
      this.chart.data.datasets[1].backgroundColor = '#188B18'; //in-today
      this.inTodayColor = '#188B18';
      this.chart.data.datasets[2].backgroundColor = '#4BD262'; //in-estim
      this.inEstimColor = '#4BD262';
      this.chart.data.datasets[3].borderColor = '#A0A0A0'; //pr-estim
      this.prEstimColor = ' #A0A0A0';
      this.chart.update();
      this.detectLine('outside');
    }
  }
  resaltLine(p) {
    setTimeout(() => this.detectLine(p), 200);
  }
  detectLine(p) {
    this.isClicked = true;
    if (p === 'outside') {
      this.timesClicked = 0;
      this.inTodayClicked = true;
      this.inEstimClicked = true;
      this.prTodayClicked = true;
      this.prEstimClicked = true;
    } else {
      let currentMoment;
      if (this.inToday.length === 0) {
        currentMoment = 0;
      } else {
        currentMoment = this.inToday.length - 1;
      }
      this.activePoints(currentMoment);
      this.timesClicked++;
    }
    if (this.timesClicked === 1) {
      switch (p) {
        case 'in-today':
          this.inTodayClicked = true;
          this.inEstimClicked = false;
          this.prTodayClicked = false;
          this.prEstimClicked = false;
          this.chart.data.datasets[0].borderColor = 'rgba(108, 229, 128, 0.2)'; //pr-today
          this.prTodayColor = 'rgba(108, 229, 128, 0.2)';
          this.chart.data.datasets[2].backgroundColor = 'rgba(75, 210, 98, 0.2)'; //in-estim
          this.inEstimColor = 'rgba(75, 210, 98, 0.2)';
          this.chart.data.datasets[3].borderColor = 'rgba(160, 160, 160, 0.2)'; //pr-estim
          this.prEstimColor = 'rgba(160, 160, 160, 0.2)';
          break;
        case 'in-estim':
          this.inEstimClicked = true;
          this.prTodayClicked = false;
          this.prEstimClicked = false;
          this.inTodayClicked = false;
          this.chart.data.datasets[0].borderColor = 'rgba(108, 229, 128, 0.2)'; //pr-today
          this.prTodayColor = 'rgba(108, 229, 128, 0.2)';
          this.chart.data.datasets[1].backgroundColor = 'rgba(24, 139, 24, 0.2)'; //in-today
          this.inTodayColor = 'rgba(24, 139, 24, 0.2)';
          this.chart.data.datasets[3].borderColor = 'rgba(160, 160, 160, 0.2)'; //pr-estim
          this.prEstimColor = 'rgba(160, 160, 160, 0.2)';
          break;
        case 'pr-today':
          this.prTodayClicked = true;
          this.inTodayClicked = false;
          this.inEstimClicked = false;
          this.prEstimClicked = false;
          this.chart.data.datasets[1].backgroundColor = 'rgba(24, 139, 24, 0.2)'; //in-today
          this.inTodayColor = 'rgba(24, 139, 24, 0.2)';
          this.chart.data.datasets[2].backgroundColor = 'rgba(75, 210, 98, 0.2)'; //in-estim
          this.inEstimColor = 'rgba(75, 210, 98, 0.2)';
          this.chart.data.datasets[3].borderColor = 'rgba(160, 160, 160, 0.2)'; //pr-estim
          this.prEstimColor = 'rgba(160, 160, 160, 0.2)';
          break;
        case 'pr-estim':
          this.inTodayClicked = false;
          this.inEstimClicked = false;
          this.prTodayClicked = false;
          this.prEstimClicked = true;
          this.chart.data.datasets[0].borderColor = 'rgba(108, 229, 128, 0.2)'; //pr-today
          this.prTodayColor = 'rgba(108, 229, 128, 0.2)';
          this.chart.data.datasets[1].backgroundColor = 'rgba(24, 139, 24, 0.2)'; //in-today
          this.inTodayColor = 'rgba(24, 139, 24, 0.2)';
          this.chart.data.datasets[2].backgroundColor = 'rgba(75, 210, 98, 0.2)'; //in-estim
          this.inEstimColor = 'rgba(75, 210, 98, 0.2)';
          break;
      }
    } else if (this.timesClicked > 1) {
      const arr = [this.inTodayClicked, this.inEstimClicked, this.prTodayClicked, this.prEstimClicked];
      const numbTrue = arr.filter((item) => item === true).length;
      switch (p) {
        case 'in-today':
          if (this.inTodayClicked && numbTrue > 1) {
            this.inTodayClicked = false;
            this.chart.data.datasets[1].backgroundColor = 'rgba(24, 139, 24, 0.2)';
            this.inTodayColor = 'rgba(24, 139, 24, 0.2)';
          } else if (!this.inTodayClicked) {
            this.inTodayClicked = true;
            this.chart.data.datasets[1].backgroundColor = '#188B18'; //in-today
            this.inTodayColor = '#188B18';
          }
          break;
        case 'in-estim':
          if (this.inEstimClicked && numbTrue > 1) {
            this.inEstimClicked = false;
            this.chart.data.datasets[2].backgroundColor = 'rgba(75, 210, 98, 0.2)'; //in-estim
            this.inEstimColor = 'rgba(75, 210, 98, 0.2)';
          } else if (!this.inEstimClicked) {
            this.inEstimClicked = true;
            this.chart.data.datasets[2].backgroundColor = '#4BD262'; //in-estim
            this.inEstimColor = '#4BD262';
          }
          break;
        case 'pr-today':
          if (this.prTodayClicked && numbTrue > 1) {
            this.prTodayClicked = false;
            this.chart.data.datasets[0].borderColor = 'rgba(108, 229, 128, 0.2)'; //pr-today
            this.prTodayColor = 'rgba(108, 229, 128, 0.2)';
          } else if (!this.prTodayClicked) {
            this.prTodayClicked = true;
            this.chart.data.datasets[0].borderColor = '#6CE580'; //pr-today
            this.prTodayColor = '#6CE580';
          }
          break;
        case 'pr-estim':
          if (this.prEstimClicked && numbTrue > 1) {
            this.prEstimClicked = false;
            this.chart.data.datasets[3].borderColor = 'rgba(160, 160, 160, 0.2)'; //pr-estim
            this.prEstimColor = 'rgba(160, 160, 160, 0.2)';
          } else if (!this.prEstimClicked) {
            this.prEstimClicked = true;
            this.chart.data.datasets[3].borderColor = '#A0A0A0'; //pr-estim
            this.prEstimColor = '#A0A0A0';
          }
          break;
      }
    }
    this.chart.update();
  }
  getRealMonth(month, indexClicked) {
    if (this.graphic === 'graphicYear') {
      this.realMoment = month;
      this.ingEstim = this.utils.roundNumber(this.inEstim[indexClicked]);
      this.ingToday = this.utils.roundNumber(this.inToday[indexClicked]);
    }
  }
  controlSliderWidth() {
    const currentWidth = window.screen.width;
    let slidesNumber: number;
    if (currentWidth > 992) {
      if (this.graphic === 'graphicDay') {
        slidesNumber = 15;
      } else {
        slidesNumber = 13;
      }
    } else {
      if (currentWidth <= 992 && currentWidth > 878) {
        slidesNumber = 17;
      } else {
        if (currentWidth <= 878 && currentWidth > 770) {
          if (this.graphic === 'graphicDay') {
            slidesNumber = 17;
          } else {
            slidesNumber = 15;
          }
        } else {
          if (currentWidth <= 770 && currentWidth > 725) {
            if (this.graphic === 'graphicDay') {
              slidesNumber = 12;
            } else {
              slidesNumber = 13;
            }
          } else {
            if (currentWidth <= 725 && currentWidth > 665) {
              if (this.graphic === 'graphicDay') {
                slidesNumber = 11;
              } else {
                slidesNumber = 12;
              }
            } else {
              if (currentWidth <= 665 && currentWidth > 610) {
                if (this.graphic === 'graphicDay') {
                  slidesNumber = 10;
                } else {
                  slidesNumber = 11;
                }
              } else {
                if (currentWidth <= 610 && currentWidth > 555) {
                  if (this.graphic === 'graphicDay') {
                    slidesNumber = 9;
                  } else {
                    slidesNumber = 10;
                  }
                } else {
                  if (currentWidth <= 555 && currentWidth > 490) {
                    if (this.graphic === 'graphicDay') {
                      slidesNumber = 8;
                    } else {
                      slidesNumber = 9;
                    }
                  } else {
                    if (currentWidth <= 490 && currentWidth > 430) {
                      if (this.graphic === 'graphicDay') {
                        slidesNumber = 7;
                      } else {
                        slidesNumber = 8;
                      }
                    } else {
                      if (currentWidth <= 430 && currentWidth > 370) {
                        if (this.graphic === 'graphicDay') {
                          slidesNumber = 6;
                        } else {
                          slidesNumber = 7;
                        }
                      } else {
                        if (currentWidth <= 370 && currentWidth > 310) {
                          if (this.graphic === 'graphicDay') {
                            slidesNumber = 5;
                          } else {
                            slidesNumber = 6;
                          }
                        } else {
                          if (this.graphic === 'graphicDay') {
                            slidesNumber = 4;
                          } else {
                            slidesNumber = 5;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.slideOpts = {
      initialSlide: 4,
      speed: 400,
      effect: 'slide',
      slidesPerView: slidesNumber,
      grabCursor: true,
      slideToClickedSlide: true,
      freeMode: true,
      centeredSlides: true,
    };
  }
  ngOnDestroy() {
    //this.chart.clear();
    window.removeEventListener('scroll', this.scroll, true);
  }
}
